import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tipoRespuesta } from './alumno.interface';

@Injectable({
  providedIn: 'root',
})
export class InscripcionService {
  url = `${environment.url}/api/v1`;
  urlFile = `${environment.urlFile}/api/upload/archivo/inscripciones`;

  constructor(private http: HttpClient) {}

  buscaAlumno(curp: string) {
    return this.http.get<tipoRespuesta>(`${this.url}/alumno/buscaAlumno/${curp}`);
  }

  getTipoDiscapacidades() {
    return this.http.get<tipoRespuesta>(`${this.url}/tipoDiscapacidad/tipoDiscapacidad`);
  }

  getDiscapacidades(disId: number) {
    return this.http.get<tipoRespuesta>(`${this.url}/discapacidad/discapacidad/${disId}`);
  }

  getAlumnoDetalles(aldMatricula: string) {
    return this.http.get<tipoRespuesta>(
      `${this.url}/alumnoDetalle/alumnoDetalle/${aldMatricula}`
    );
  }

  guardaAlumnoDetalles(
    aldMatricula: string,
    aldZonaIndigena: number,
    aldServicioMedico: number,
    aldModalidad: number,
    lenguaIndigena: number
  ) {
    let data = {
      aldMatricula: aldMatricula,
      aldZonaIndigena: aldZonaIndigena,
      aldServicioMedico: aldServicioMedico,
      aldModalidad: aldModalidad,
      aldLenguaIndigena: lenguaIndigena,
    };
    return this.http.post<tipoRespuesta>(`${this.url}/alumnoDetalle/guardaDatosAlumno`, data);
  }

  guardaAlumnoDiscapacidades(discapacidades: any[], matricula: string) {
    let data = { ...discapacidades };
    return this.http.post<tipoRespuesta>(
      `${this.url}/alumnoDetalle/guardaAlumnoDiscapacidades/${matricula}`,
      discapacidades
    );
  }

  getAlumnoDiscapacidades(matricula: string) {
    return this.http.get<tipoRespuesta>(
      `${this.url}/alumnoDetalle/alumnoDiscapacidad/${matricula}`
    );
  }

  updFileActa(matricula: string, archivo: string) {
    let data = {
      matricula: matricula,
      archivo: archivo,
    };
    return this.http.post<tipoRespuesta>(`${this.url}/alumnoDetalle/updFileActa`, data);
  }

  updFileCurp(matricula: string, archivo: string) {
    let data = {
      matricula: matricula,
      archivo: archivo,
    };
    return this.http.post<tipoRespuesta>(`${this.url}/alumnoDetalle/updFileCurp`, data);
  }

  updFileSecundaria(matricula: string, archivo: string) {
    let data = {
      matricula: matricula,
      archivo: archivo,
    };
    return this.http.post<tipoRespuesta>(`${this.url}/alumnoDetalle/updFileSecundaria`, data);
  }

  updFileFoto(matricula: string, archivo: string) {
    let data = {
      matricula: matricula,
      archivo: archivo,
    };
    return this.http.post<tipoRespuesta>(
      `${this.url}/alumnoDetalle/updFileFoto`,
      data
    );
  }

  updFileCredencial(matricula: string, archivo: string) {
    let data = {
      matricula: matricula,
      archivo: archivo,
    };
    return this.http.post<tipoRespuesta>(
      `${this.url}/alumnoDetalle/updFileCredencial`,
      data
    );
  }

  updFileRecibo(matricula: string, archivo: string) {
    let data = {
      matricula: matricula,
      archivo: archivo,
    };
    return this.http.post<tipoRespuesta>(
      `${this.url}/alumnoDetalle/updFileRecibo`,
      data
    );
  }

  updLineaCaptura(matricula: string, captura: number) {
    let data = {
      matricula: matricula,
      captura: captura,
    };
    return this.http.post<tipoRespuesta>(
      `${this.url}/alumnoDetalle/UpdLineaCaptura`,
      data
    );
  }

  actualizaArchivo(archivo: File, ruta: string, nombre: string) {
    console.log(archivo);
    console.log(ruta);
    console.log(nombre);
    const endPoint = `${this.urlFile}`;
    const formData = new FormData();
    formData.append('archivo', archivo);
    formData.append('nombre', nombre);
    formData.append('ruta', ruta);
    return this.http.post<tipoRespuesta>(`${endPoint}`, formData);
  }
}
