<div class="container mt-5">
</div>
<div class="form">
  <div class="info">
    <h1>Inscripciones en Linea</h1>
  </div>
  <div class="thumbnail"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/hat.svg"></div>

  <form autocomplete="off" #f="ngForm" (ngSubmit)="onSubmit(f)" class="login-form">
    <input type="text" placeholder="Usuario" [ngModel]="usuario.user" class='form-control' id='user' name="user" minlength="3" required>
    <input type="password" placeholder="Contraseña" [ngModel]="usuario.pass" class='form-control' id='pass' name="pass" minlength="3" required>
    <button  class="btn btn-block btn-primary">Ingresar</button>
  </form>

</div>
