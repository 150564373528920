export const environment = {
  production: false,
  // AuthUrl: 'https://auth.cobatab.edu.mx',
  // AutPort: '3005',
  // url: 'https://nuevoingresosrv.cobatab.edu.mx',
  // urlFile: 'http://127.0.0.1',
  // portFile: '3001',
  AuthUrl: 'https://auth.cobatab.edu.mx',
  // url: 'http://10.0.10.71:3006',
  url: 'https://nuevoingreso.cobatab.edu.mx',
  urlFile: 'https://file.cobatab.edu.mx',
  // AuthUrl: 'http://127.0.0.1',
  // url: 'http://187.189.147.227',
  // url: 'http://127.0.0.1:3006',
  // urlFile: 'http://187.189.147.227',
};
