import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoginService } from './login.service';
import { LoginModel } from './usuario.Model';
// import md5 from 'md5-hash';
import { MD5 } from 'crypto-js';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  usuario!: LoginModel;
  elUser!: LoginModel;

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });

  constructor(private router: Router, private _loginSrv: LoginService) {}

  ngOnInit(): void {
    sessionStorage.clear();
    this.usuario = new LoginModel();
    this.elUser = new LoginModel();
  }

  onSubmit(f: NgForm): void {
    console.log('on submit');

    this.elUser.user = f.value.user;
    this.elUser.pass = f.value.pass;
    // this.elUser.pass = md5(f.value.pass);
    this.elUser.pass = MD5(f.value.pass).toString();
    console.log(this.elUser.pass);

    Swal.fire({
      title: 'Autenticando...',
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    Swal.showLoading();
    this._loginSrv.login(this.elUser).subscribe((res: any) => {
      Swal.close();
      console.log(res);
      if (res.error) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Usuario o Contraseña Incorrecto',
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      sessionStorage.setItem('usuLogin', this.elUser.user!)
      switch (res.rol) {
        case 'DIRECTOR':
          this.accesa(0);
          break;
        case 'SUBDIRECTOR':
          this.accesa(0);
          break;
        case 'SUBDIRECTOR EMSAD':
          this.accesa(0);
          break;
        case 'RESPONSABLE EMSAD':
          this.accesa(0);
          break;
        case 'SISTEMA':
          this.accesa(0);
          break;
        case 'CONTROL ESCOLAR':
          this.accesa(0);
          break;
        case 'ADMON CONTROL ESCOLAR':
          this.accesa(0);
          break;
        default:
          this.accesoDenegado();
          break;
      }
    });
  }

  accesa(permiso: number): void {
    this.Toast.fire({
      icon: 'success',
      title: 'Logueado',
    });
    // tslint:disable-next-line:triple-equals
    if (permiso == 0) {
      this.router.navigateByUrl('/controlEscolar');
    } else {
      this.router.navigateByUrl('/planteles');
    }
  }

  accesoDenegado(): void {
    Swal.fire({
      icon: 'warning',
      text: 'No tiene acceso a la plataforma',
    });
  }
}
