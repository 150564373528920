<div class="card">
  <div class="card-body">
    <div class="jumbotron">
      <div class="container">
        <div class="row align-items-start">
          <div class="col-md-8">
            <h2 class="display-4" id="titulo">
              Colegio de Bachilleres de Tabasco
            </h2>
            <h3>SOLICITUD DE INSCRIPCIÓN Y REINSCRIPCIÓN</h3>
          </div>
          <div class="col-md-4 align-items-end">
            <img
              src="../assets/logo_cobatab_lema-01.png"
              alt="COBATAB"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="info">
          <h1 class="text-center">Calendario de Registro</h1>
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th width="250" class="text-center">Fecha</th>
                    <th width="300" class="text-center">
                      Letra Apellido Paterno
                    </th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let fecha of fechas">
                    <td class="text-center">{{fecha.fecha | date: "dd 'de ' MMMM, yyyy"}}</td>
                    <td class="text-center">{{fecha.letras}}</td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </div>
    </div>
    <form #form="ngForm" *ngIf="activacion">
      <div class="container">
        <h3 class="text-center">
          Para comenzar el trámite, introduzca la CURP del alumno
        </h3>
        <h5 class="text-center">
          Las letras asignadas para el dia de hoy son: {{ letrasHoy }}
        </h5>
        <!-- , el acceso estará regulado de acuerdo a su
          <strong>Primera
            letra del apellido paterno</strong>.
        </p>
        <p>Puede revisar el calendario de registros, aquí.</p> -->

        <div
          class="row animate__animated animate__fadeIn animate_slow"
          *ngIf="!encontrado"
        >
          <div class="col-sm-6 col-md-6 col-xs-6 mb-2 offset-sm-3">
            <div class="input-group mb-4">
              <input
                type="text"
                class="form-control"
                placeholder="CURP del alumno"
                [(ngModel)]="curp"
                name="curp"
                value=""
                pattern="([A-Z]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[A-Z]{3}[0-9A-Z]\d)"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  id="btnSearch"
                  (click)="encuentralo()"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card animate__animated animate__fadeIn animate_slow mb-2"
        *ngIf="encontrado"
      >
        <div class="card-header">Datos Personales</div>

        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-12 col-md-4 mb-2">
              <label for="fechaSolicitud">Fecha de Solicitud:</label>
              <input
                type="text"
                class="form-control"
                id="fechaSolicitud"
                [value]="fechaHoy"
                readonly
              />
            </div>
            <div class="col-sm-6 col-md-4 mb-2">
              <label for="periodo">Periodo:</label>
              <input
                type="text"
                class="form-control"
                id="periodo"
                [value]="periodo"
                readonly
              />
            </div>
            <div class="col-sm-6 col-md-4 mb-2">
              <label for="matricula">Matricula:</label>
              <input
                type="text"
                class="form-control"
                id="matricula"
                [value]="datosAlumno.aluMatricula"
                readonly
              />
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-sm-6 col-md-6 mb-2">
              <label for="centroEducativo">Centro Educativo:</label>
              <input
                type="text"
                class="form-control"
                id="centroEducativo"
                [value]="datosAlumno.uniadmDesc"
                readonly
              />
            </div>
            <div class="col-sm-6 col-md-6 mb-2">
              <label for="turno">Turno:</label>
              <input
                type="text"
                class="form-control"
                id="turno"
                [value]="datosAlumno.turno"
                readonly
              />
            </div>
          </div>
          <hr />

          <div class="row mb-2">
            <div class="col-sm-6 col-md-6 col-xs-12 mb-2">
              <label for="nombreAlumno">Nombre:</label>
              <input
                type="text"
                class="form-control"
                id="nombreAlumno"
                [value]="datosAlumno.aluNombre"
                readonly
              />
            </div>
            <div class="col-sm-6 col-md-6 col-xs-12 mb-2">
              <label for="apPaterno">Apellido Paterno</label>
              <input
                type="text"
                class="form-control"
                id="ApPaterno"
                [value]="datosAlumno.aluPaterno"
                readonly
              />
            </div>
            <div class="col-sm-6 col-md-6 col-xs-12 mb-2">
              <label for="apMaterno">Apellido Materno</label>
              <input
                type="text"
                class="form-control"
                id="apMaterno"
                [value]="datosAlumno.aluMaterno"
                readonly
              />
            </div>

            <div class="col-sm-6 col-md-6 col-xs-12 mb-2">
              <label for="CURP">CURP:</label>
              <input
                type="text"
                class="form-control"
                id="CURP"
                [value]="datosAlumno.aluCurp"
                readonly
              />
            </div>
            <div class="col-sm-6 col-md-4 col-xs-12 mb-2">
              <label for="fechaNacimiento">Fecha de nacimiento:</label>
              <input
                type="text"
                class="form-control"
                id="fechaNacimiento"
                [value]="datosAlumno.aluFechaNacimiento"
                readonly
              />
            </div>
            <div class="col-sm-6 col-md-4 col-xs-12 mb-2">
              <label for="sexo">Sexo:</label>
              <input
                type="text"
                class="form-control"
                id="sexo"
                [value]="datosAlumno.sexo"
                readonly
              />
            </div>

            <div class="col-sm-6 col-md-4 col-xs-12 mb-2">
              <label for="nacionalidad">Nacionalidad:</label>
              <input
                type="text"
                class="form-control"
                id="nacionalidad"
                [value]="datosAlumno.nacionalidad"
                readonly
              />
            </div>
            <div class="col-sm-12 col-md-8 col-xs-12 mb-2">
              <label for="nacidoEn">Nacido en:</label>
              <input
                type="text"
                class="form-control"
                id="nacidoEn"
                [value]="datosAlumno.edoNacimiento"
                readonly
              />
            </div>
            <div class="col-sm-6 col-md-4 col-xs-12 mb-2">
              <label for="codiigoPostal">Código Postal:</label>
              <input
                type="text"
                class="form-control"
                id="codiigoPostal"
                [value]="datosAlumno.aluCp"
                readonly
              />
            </div>

            <div class="col-sm-12 col-xs-12 mb-2">
              <label for="ciudadvp">Ciudad / Villa / Poblado:</label>
              <input
                type="text"
                class="form-control"
                id="ciudadvp"
                [value]="datosAlumno.municipioDireccion"
                readonly
              />
            </div>
            <div class="col-sm-6 col-md-6 col-xs-12 mb-2">
              <label for="telefono">Teléfono:</label>
              <input
                type="text"
                class="form-control"
                id="telefono"
                [value]="datosAlumno.aluTelefonoCasa"
                readonly
              />
            </div>

            <div class="col-sm-6 col-md-6 col-xs-12 mb-2">
              <label for="celular">Telefono Celular:</label>
              <input
                type="text"
                class="form-control"
                id="celular"
                [value]="datosAlumno.aluCelular"
                readonly
              />
            </div>
            <div class="col-sm-12 col-md-6 col-xs-12 mb-2">
              <label for="correoE">Correo Electrónico:</label>
              <input
                type="text"
                class="form-control"
                id="correoE"
                [value]="datosAlumno.aluEmail"
                readonly
              />
            </div>

            <div class="col-sm-12 col-md-6 col-xs-12 mb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="tieneDiscapacidad"
                  id="tieneDiscapacidad"
                  name="tieneDiscapacidad"
                  (change)="dimeDis()"
                  [disabled]="tieneDetalles"
                />
                <label for="discapacidad" class="form-check-label">
                  Presenta Algún tipo de discapacidad:
                </label>
              </div>
              <input
                type="text"
                class="form-control"
                id="discapacidad"
                value="NO | TIPO"
                readonly
              />
            </div>
          </div>

          <hr />
          <div
            class="card animate__animated animate__fadeIn animate_slow"
            *ngIf="tieneDiscapacidad"
          >
            <div class="card-header">Discapacidades del Alumno</div>
            <div class="card-body">
              <div class="row" *ngIf="!tieneDetalles">
                <div class="col-md-5">
                  Tipo de Discapacidad
                  <select
                    (change)="getDiscapacidad()"
                    class="form-control"
                    name="alTipoDiscapacidad"
                    id="alTipoDiscapacidad"
                    [(ngModel)]="alTipoDiscapacidad"
                  >
                    <option
                      *ngFor="let tipoD of tipoDiscapacidades"
                      value="{{ tipoD.tdiId }}/{{ tipoD.tdiDescripcion }}"
                    >
                      {{ tipoD.tdiDescripcion }}
                    </option>
                  </select>
                </div>
                <div class="col-md-5">
                  Discapacidad
                  <select
                    (change)="updDiscapacidad()"
                    class="form-control"
                    name="alDiscapacidad"
                    id="alDiscapacidad"
                    [(ngModel)]="alDiscapacidad"
                  >
                    <option
                      *ngFor="let disc of discapacidades"
                      value="{{ disc.dscId }}/{{ disc.dscDescripcion }}"
                    >
                      {{ disc.dscDescripcion }}
                    </option>
                  </select>
                </div>

                <div class="col-md-2">
                  <button class="btn btn-primary" (click)="revisaDAtos()">
                    Agregar
                  </button>
                </div>
              </div>
              <br />
              <table class="table table-sm table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Discapacidad</th>
                    <th *ngIf="!tieneDetalles">Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let disca of discapacidadesAlumno; index as i">
                    <td>{{ disca.disAlTipo }}</td>
                    <td>{{ disca.disAlDisc }}</td>
                    <td *ngIf="!tieneDetalles">
                      <i
                        class="fa fa-trash text-danger cursor"
                        (click)="eliminaDiscapacidadAlumno(i)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr />

          <div class="card mb-4">
            <div class="card-header">Información del Tutor</div>
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-sm-6 col-md-6 col-xs-12 mb-2">
                  <label for="apPaternoTutor">Apellido Paterno</label>
                  <input
                    type="text"
                    class="form-control"
                    id="apPaternoTutor"
                    name="apPaternoTutor"
                    [value]="datosAlumno.tutPaterno"
                    readonly
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-xs-12 mb-2">
                  <label for="apMaternoTutor">Apellido Materno</label>
                  <input
                    type="text"
                    class="form-control"
                    id="apMaternoTutor"
                    name="apMaternoTutor"
                    [value]="datosAlumno.tutMaterno"
                    readonly
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-xs-12 mb-2">
                  <label for="nombreTutor">Nombre:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombreTutor"
                    name="nombreTutor"
                    [value]="datosAlumno.tutNombre"
                    readonly
                  />
                </div>

                <div class="col-sm-6 col-md-6 col-xs-12 mb-2">
                  <label for="telefonoTutor">Teléfono:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="telefonoTutor"
                    name="telefonoTutor"
                    [value]="datosAlumno.tutCelular"
                    readonly
                  />
                </div>
                <div class="col-md-12 col-xs-12 mb-2">
                  <label for="domicilioTutor">Domicilio particular:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="domicilioTutor"
                    name="domicilioTutor"
                    [value]="datosAlumno.tutCalle"
                    readonly
                  />
                </div>

                <div class="col-sm-8 col-xs-12 mb-2">
                  <label for="centroTrabajoTutor">Centro de trabajo:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="centroTrabajoTutor"
                    name="centroTrabajoTutor"
                    [value]="datosAlumno.tutDomicilioLaboral"
                    readonly
                  />
                </div>
                <div class="col-sm-4 col-md-4 col-xs-12 mb-2">
                  <label for="telefonoTrabajoTutor">Teléfono:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="telefonoTrabajoTutor"
                    name="telefonoTrabajoTutor"
                    [value]="datosAlumno.tutTelefonoTrabajo"
                    readonly
                  />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-12 col-xs-12 mb-2">
                  <label for="domicilioTrabajoTutor">Domicilio laboral:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="domicilioTrabajoTutor"
                    name="domicilioTrabajoTutor"
                    [value]="datosAlumno.tutDomicilioLaboral"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-4">
            <div class="card-header">Solicitamos Inscripción</div>
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-sm-12 col-xs-12 mb-2">
                  <label for="escuelaProcedencia">
                    Escuela de procedencia:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="escuelaProcedencia"
                    name="escuelaProcedencia"
                    [value]="datosAlumno.secCentroEducativo"
                    readonly
                  />
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-sm-12 col-md-6 col-xs-12 mb-2">
                  <label for="fExpecicionCertificado">
                    Fecha de Expedición del Certificado
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="fExpecicionCertificado"
                    [value]="datosAlumno.aluFechaCertificado"
                    name="fExpecicionCertificado"
                    readonly
                  />
                </div>
                <div class="col-sm-12 col-md-6 col-xs-12 mb-2">
                  <label for="promedioAcademico">Promedio Académico</label>
                  <input
                    type="text"
                    class="form-control"
                    id="promedioAcademico"
                    name="promedioAcademico"
                    [value]="datosAlumno.aluPromedioSec"
                    readonly
                  />
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-sm-12 col-xs-12 mb-2">
                  <label for="municipioEstadoPais">Dirección</label>
                  <input
                    type="text"
                    class="form-control"
                    id="municipioEstadoPais"
                    name="municipioEstadoPais"
                    value="Calle {{ datosAlumno.secDomicilio }} {{
                      datosAlumno.secNumExt
                    }}"
                    readonly
                  />
                </div>
              </div>

              <div class="row g-3 align-items-center">
                <div class="col-md-3">
                  <label for="staticEmail" class="col-form-label mb-2">
                    Radica en Zona Indígena
                  </label>
                </div>
                <div class="col-auto">
                  <div class="btn-group ml-3 mb-2">
                    <button
                      type="button"
                      [disabled]="tieneDetalles"
                      [ngClass]="{
                        'btn-primary': zonaIndigena == 1,
                        'btn-outline-primary': zonaIndigena == 0
                      }"
                      class="btn"
                      (click)="setZonaIndigena(1)"
                    >
                      Si
                    </button>
                    <button
                      type="button"
                      [disabled]="tieneDetalles"
                      [ngClass]="{
                        'btn-primary': zonaIndigena == 0,
                        'btn-outline-primary': zonaIndigena == 1
                      }"
                      class="btn btn-outline-primary"
                      (click)="setZonaIndigena(0)"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>

              <!-- Lengua Indigena -->
              <div class="row g-3 align-items-center">
                <div class="col-md-3">
                  <label for="staticEmail" class="col-form-label mb-2">
                    ¿Habla alguna lengua indigena?
                  </label>
                </div>
                <div class="col-auto">
                  <div class="btn-group ml-3 mb-2">
                    <button
                      type="button"
                      [disabled]="tieneDetalles"
                      [ngClass]="{
                        'btn-primary': lenguaIndigena > 0,
                        'btn-outline-primary': lenguaIndigena == 0
                      }"
                      class="btn"
                      (click)="setLenguaIndigena(1)"
                    >
                      Si
                    </button>
                    <button
                      type="button"
                      [disabled]="tieneDetalles"
                      [ngClass]="{
                        'btn-primary': lenguaIndigena == 0,
                        'btn-outline-primary': lenguaIndigena > 0
                      }"
                      class="btn btn-outline-primary"
                      (click)="setLenguaIndigena(0)"
                    >
                      No
                    </button>
                  </div>
                </div>
                <div class="col-md-3 text-end" *ngIf="lenguaIndigena != 0">
                  <label for="staticEmail" class="col-form-label mb-2">
                    ¿Que lengua indígena habla?
                  </label>
                </div>

                <div class="col-auto" *ngIf="lenguaIndigena != 0">
                  <select
                    [(ngModel)]="lenguaIndigena"
                    name="lenguaIndigena"
                    id="lenguaIndigena"
                    class="form-control"
                    [disabled]="tieneDetalles"
                  >
                    <option value="1">Ayapaneco</option>
                    <option value="2">Chontal</option>
                    <option value="3">Lacandón</option>
                    <option value="4">Tzotzil</option>
                    <option value="5">Chol</option>
                  </select>
                </div>
              </div>

              <!--Servicio Medico -->
              <div class="row g-3 align-items-center">
                <div class="col-md-3">
                  <label for="staticEmail" class="col-form-label mb-2">
                    ¿Tienes algun Servicio Médico?
                  </label>
                </div>

                <div class="col-auto">
                  <div class="btn-group ml-3 mb-2">
                    <button
                      type="button"
                      [disabled]="tieneDetalles"
                      [ngClass]="{
                        'btn-primary': servicioMedico > 0,
                        'btn-outline-primary': servicioMedico == 0
                      }"
                      class="btn"
                      (click)="setServicioMEdico(1)"
                    >
                      Si
                    </button>
                    <button
                      type="button"
                      [disabled]="tieneDetalles"
                      [ngClass]="{
                        'btn-primary': servicioMedico == 0,
                        'btn-outline-primary': servicioMedico > 0
                      }"
                      class="btn btn-outline-primary"
                      (click)="setServicioMEdico(0)"
                    >
                      No
                    </button>
                  </div>
                </div>

                <div
                  class="col-md-3 text-end animate__animated animate__fadeIn"
                  *ngIf="servicioMedico != 0"
                >
                  <label for="staticEmail" class="col-form-label mb-2">
                    Indique cuál
                  </label>
                </div>

                <div
                  class="col-auto animate__animated animate__fadeIn"
                  *ngIf="servicioMedico != 0"
                >
                  <select
                    name="servicioMedico"
                    id="servicioMedico"
                    [ngModel]="servicioMedico"
                    class="form-control"
                    [disabled]="tieneDetalles"
                    (change)="dimeSM($event)"
                  >
                    <option value="1">PEMEX</option>
                    <option value="2">ISSET</option>
                    <option value="3">IMSS</option>
                    <option value="4">ISSSTE</option>
                    <option value="5">INSAVI</option>
                    <option value="6">Militar</option>
                    <option value="7">Otro</option>
                  </select>
                </div>
              </div>
              <!--  -->

              <div class="row mb-2 d-none">
                <label
                  for="staticEmail"
                  class="col-sm-12 col-md-7 col-lg-5 col-xl-4 col-form-label mb-2"
                >
                  Modalidad de estudio que te interesa cursar
                </label>
                <div class="btn-group ml-3">
                  <button
                    type="button"
                    [disabled]="tieneDetalles"
                    [ngClass]="{
                      'btn-primary': modalidadEstudio == 1,
                      'btn-outline-primary': modalidadEstudio == 2
                    }"
                    class="btn"
                    (click)="setModalidadEstudio(1)"
                  >
                    Presencial
                  </button>
                  <button
                    type="button"
                    [disabled]="tieneDetalles"
                    [ngClass]="{
                      'btn-primary': modalidadEstudio == 2,
                      'btn-outline-primary': modalidadEstudio == 1
                    }"
                    class="btn btn-outline-primary"
                    (click)="setModalidadEstudio(2)"
                  >
                    Virtual
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-sm-10 mx-auto" *ngIf="!tieneDetalles">
              <button
              id="btnGuardaDetalleAlumno"
              [disabled]="clickGuarda"
                class="btn btn-block btn-outline-primary"
                (click)="termina()"
              >
                Guardar Información
              </button>
            </div>
          </div>

          <div class="card mb-2">
            <div class="card-header"><strong>IMPORTANTE</strong></div>
            <div class="card-body">
              <form>
                <div class="form-group row">
                  <label for="staticEmail" class="col-sm-3 col-form-label">
                    Matricula del Alumno:
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext"
                      [value]="datosAlumno.aluMatricula"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputPassword" class="col-sm-3 col-form-label">
                    Correo Institucional:
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext"
                      value="{{ datosAlumno.aluMatricula }}@cobatab.edu.mx"
                    />
                    <small>Quedará activo en aproximadamente 15 dias.</small>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputPassword" class="col-sm-3 col-form-label">
                    Plantel:
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control-plaintext"
                      [value]="datosAlumno.uniadmDesc"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputPassword" class="col-sm-3 col-form-label">
                    Turno:
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control-plaintext"
                      [value]="datosAlumno.turno"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div *ngIf="tieneDetalles" class="card mt-2">
            <div class="card-header">
              Documentación
              <br />
              <span id="lbError" style="color: red"></span>
            </div>
            <div class="card-body">
              <div class="row mb-2">
                <!-- <div class="col-xl-6 col-sm-8 col-md-6 col-xs-12 mb-2">
                  <label class="col-xl-12" for="actaNacimiento"> Acta de Nacimiento </label>
                  <small>*Tamaño maximo archivo 2 MB </small>
                  <div class="btn-group mb-2" *ngIf="rutaActaNacimiento.length == 0 || aldFileActaStatus == 2">
                    <input type="file" class="form-control" id="actaNacimiento" accept="application/pdf"/>
                    <button (change)="agarraArchivo($event,'actaNacimiento')" *ngIf="tipoSubir == 'actaNacimiento'" class="btn btn-primary" (click)="subeArchivo('actaNacimiento')"> Subir </button>
                  </div>

                  <div class="btn-group mb-2" *ngIf="rutaActaNacimiento.length > 0">
                    <div class="btn-group mb-2">
                      <span class="alert" [ngClass]="{'alert-primary': aldFileActaStatus == 1,'alert-warning': aldFileActaStatus == 2,'alert-success': aldFileActaStatus == 3}">
                        <a class="btn" [href]="rutaActaNacimiento" target="_blank"><i class="far fa-2x fa-file"></i></a>
                        {{ aldFileActaStatusDesc }}
                        {{ aldFileActaMotivo }}
                        <small>{{ aldFileActaDate }}</small>
                      </span>
                    </div>
                  </div>
                </div> -->

                <div class="col-sm-8 col-md-6 col-xs-12 mb-2">
                  <div class="card">
                    <div class="card-header">
                      <label class="col-xl-12" for="curp">CURP</label>
                    </div>
                    <div class="card-body">
                      <small
                        *ngIf="rutaCURP.length == 0 || aldFileCurpStatus == 2"
                        >*Tamaño maximo archivo 2 MB
                      </small>
                      <div
                        class="btn-group mb-2"
                        *ngIf="rutaCURP.length == 0 || aldFileCurpStatus == 2"
                      >
                        <input
                          (change)="agarraArchivo($event, 'curp')"
                          type="file"
                          class="form-control"
                          id="curp"
                          accept="application/pdf"
                        />
                        <button
                          *ngIf="tipoSubir == 'curp'"
                          class="btn btn-primary"
                          (click)="subeArchivo('CURP')"
                        >
                          Subir
                        </button>
                      </div>

                      <div class="btn-group mb-2" *ngIf="rutaCURP.length > 0">
                        <div class="btn-group mb-2">
                          <span
                            class="alert"
                            [ngClass]="{
                              'alert-primary': aldFileCurpStatus == 1,
                              'alert-warning': aldFileCurpStatus == 2,
                              'alert-success': aldFileCurpStatus == 3
                            }"
                          >
                            <a class="btn" [href]="rutaCURP" target="_blank">
                              <i class="fa fa-2x fa-file"></i>
                            </a>
                            {{ aldFileCurpStatusDesc }}
                            <small>{{ aldFileCurpDate }}</small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-sm-8 col-md-6 col-xs-12 mb-2">
                  <label class="col-xl-12" for="csec"> Certificado de Secundaria </label>
                  <small>*Tamaño maximo archivo 2 MB </small>
                  <div class="btn-group mb-2" *ngIf="rutaCertSecundaria.length == 0 || aldFileSecundariaStatus == 2">
                    <input
                      type="file"
                      class="form-control"
                      id="csec"
                      accept="image/x-png,image/jpeg,application/pdf"
                      />

                    <button *ngIf="tipoSubir == 'csec'" class="btn btn-primary" (click)="subeArchivo('certSecundaria')" (change)="agarraArchivo($event, 'csec')">Subir</button>
                  </div>

                  <div class="btn-group mb-2" *ngIf="rutaCertSecundaria.length > 0">
                    <div class="btn-group mb-2">
                      <span
                        class="alert"
                        [ngClass]="{'alert-primary': aldFileSecundariaStatus == 1, 'alert-warning': aldFileSecundariaStatus == 2, 'alert-success': aldFileSecundariaStatus == 3}">
                        <a class="btn" [href]="rutaCertSecundaria" target="_blank"> <i class="far fa-2x fa-file"></i> </a>
                        {{ aldFileSecundariaStatusDesc }}
                        <small>{{ aldFileSecundariaDate }}</small>
                      </span>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-sm-8 col-md-6 col-xs-12 mb-2">
                  <label class="col-xl-12" for="foto">Fotografia<small>*Tamaño maximo archivo 2 MB </small></label>

                  <div class="btn-group mb-2" *ngIf="rutaFotografia.length == 0 || aldFileFotoStatus == 2">
                    <input type="file" class="form-control" id="foto" accept="image/x-png,image/jpeg"/>
                    <button *ngIf="tipoSubir == 'foto'" class="btn btn-primary" (click)="subeArchivo('fotografia')" (change)="agarraArchivo($event, 'foto')"> Subir </button>
                  </div>

                  <div class="btn-group mb-2" *ngIf="rutaFotografia.length > 0">
                    <div class="btn-group mb-2">
                      <span
                        class="alert"
                        [ngClass]="{'alert-primary': aldFileFotoStatus == 1,'alert-warning': aldFileFotoStatus == 2,'alert-success': aldFileFotoStatus == 3}">
                        <a class="btn" [href]="rutaFotografia" target="_blank"><i class="far fa-2x fa-file"></i></a>
                        {{ aldFileFotoStatusDesc }}
                        <small>{{ aldFileFotoDate }}</small>
                      </span>
                    </div>
                  </div>
                </div> -->

                <div class="col-sm-8 col-md-6 col-xs-12 mb-2">
                  <div class="card">
                    <div class="card-header">
                      <label class="col-xl-12" for="ineTutor">
                        Credencial INE del Tutor
                      </label>
                    </div>
                    <div class="card-body">
                      <small
                        *ngIf="
                          rutaCredencial.length == 0 ||
                          aldFileCredencialStatus == 2
                        "
                        >*Tamaño maximo archivo 2 MB
                      </small>

                      <div
                        class="btn-group mb-2"
                        *ngIf="
                          rutaCredencial.length == 0 ||
                          aldFileCredencialStatus == 2
                        "
                      >
                        <input
                          type="file"
                          class="form-control"
                          id="ineTutor"
                          accept="application/pdf,image/x-png,image/gif,image/jpeg"
                          (change)="agarraArchivo($event, 'ineTutor')"
                        />
                        <button
                          *ngIf="tipoSubir == 'ineTutor'"
                          class="btn btn-primary"
                          (click)="subeArchivo('ineTutor')"
                        >
                          Subir
                        </button>
                      </div>

                      <div
                        class="btn-group mb-2"
                        *ngIf="rutaCredencial.length > 0"
                      >
                        <div class="btn-group mb-2">
                          <span
                            class="alert"
                            [ngClass]="{
                              'alert-primary': aldFileCredencialStatus == 1,
                              'alert-warning': aldFileCredencialStatus == 2,
                              'alert-success': aldFileCredencialStatus == 3
                            }"
                          >
                            <a
                              class="btn"
                              [href]="rutaCredencial"
                              target="_blank"
                            >
                              <i class="fa fa-2x fa-file"></i>
                            </a>
                            {{ aldFileCredencialStatusDesc }}
                            <small>{{ aldFileCredencialDate }}</small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-sm-8 col-md-6 col-xs-12 mb-2">
                  <label class="col-xl-12" for="recibo">Recibo:</label>
                  <small>*Tamaño maximo archivo 2 MB </small>
                  <div
                    class="btn-group mb-2"
                    *ngIf="rutaRecibo.length == 0 || aldFileReciboStatus == 2"
                  >
                    <input
                      type="file"
                      class="form-control"
                      id="recibo"
                      name="recibo"
                      accept="application/pdf,image/x-png,image/gif,image/jpeg"
                      />
                      <button
                      (change)="agarraArchivo($event, 'recibo')"
                      *ngIf="tipoSubir == 'recibo'"
                      class="btn btn-primary"
                      (click)="subeArchivo('recibo')"
                    >
                      Subir
                    </button>
                  </div>

                  <div class="btn-group mb-2" *ngIf="rutaRecibo.length > 0">
                    <div class="btn-group mb-2">
                      <span
                        class="alert"
                        [ngClass]="{
                          'alert-primary': aldFileReciboStatus == 1,
                          'alert-warning': aldFileReciboStatus == 2,
                          'alert-success': aldFileReciboStatus == 3
                        }"
                      >
                        <a class="btn" [href]="rutaRecibo" target="_blank"
                          ><i class="far fa-2x fa-file"></i
                        ></a>
                        {{ aldFileReciboStatusDesc }}
                        <small>{{ aldFileReciboDate }}</small>
                      </span>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-sm-8 col-md-6 col-xs-12 mb-2">
                  <label class="col-xl-12" for="lineaCaptura"
                    >Línea de Captura<span *ngIf="lineaDeCaptura != 0"
                      >: {{ lineaCaptura }}</span
                    ></label
                  >
                  <div class="btn-group mb-2" *ngIf="lineaDeCaptura == 0">
                    <input
                      type="number"
                      min="0"
                      class="form-control"
                      id="lineaCaptura"
                      name="lineaCaptura"
                      [(ngModel)]="lineaCaptura"
                    />
                    <button
                      class="btn btn-primary"
                      (click)="guardaLineaCaptura()"
                    >
                      Guardar
                    </button>
                  </div>
                </div> -->
              </div>
            </div>
            <button
              type="button"
              name="cerrar"
              id="cerrar"
              class="btn btn-primary btn-lg btn-block"
              (click)="cerrar()"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
