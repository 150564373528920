import { Component } from '@angular/core';
import { Router } from '@angular/router';
// import DataTables from 'datatables.net';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ControlEscolarService } from '../control-escolar/control-escolar.service';
import { infoPlantel, Alumnos } from '../control-escolar/datos.interface';
import { tipoRespuesta } from '../inscripcion/alumno.interface';

@Component({
  selector: 'app-planteles',
  templateUrl: './planteles.component.html',
  styleUrls: ['./planteles.component.css'],
})
export class PlantelesComponent {
  uniadmClave!: number;
  aluSemestre: number = 0;
  informacion!: infoPlantel;
  completados: number = 0;
  conDocumentosPendientes: number = 0;
  sinDocumentos: number = 0;
  sinRegistro: number = 0;
  total: number = 0;
  unidadAdministrativa!: string;
  alumnos: Alumnos[] = [];
  infoUpd: Alumnos[] = [];
  plantel!: string;
  rutaArchivo = `${environment.urlFile}/api/upload/archivo`;

  rol = sessionStorage.getItem('rol');
  token = sessionStorage.getItem('token');
  nombreCompleto = sessionStorage.getItem('nombreCompleto');
  // dtOptions: DataTables.Settings = {};
  /*// urlFile = `${environment.url}:${environment.portFile}/api/upload/archivo`;*/

  porCompletados!: number;
  porConDocumentosPendientes!: number;
  porSinDocumentos!: number;
  porSinRegistro!: number;
  constructor(
    private servicio: ControlEscolarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.uniadmClave = parseInt(sessionStorage.getItem('uniadmClave')!);
    console.log(this.uniadmClave);
    this.nombreCompleto = sessionStorage.getItem('unidadAdministrativa');
    this.aluSemestre = parseInt(sessionStorage.getItem('aluSemestre')!);
    console.log(this.aluSemestre);
    this.getDetallesPlantel(this.uniadmClave, this.aluSemestre);
    this.getAlumnosPlantel(this.uniadmClave, this.aluSemestre);
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 25,
    //   processing: true,
    //   language: {
    //     processing: 'Procesando...',
    //     search: 'Buscar:',
    //     lengthMenu: 'Mostrar _MENU_ registros',
    //     info: 'Mostrando desde _START_ al _END_ de _TOTAL_ registros',
    //     infoEmpty: 'Mostrando ningún registro.',
    //     infoFiltered: '(filtrado _MAX_ registro total)',
    //     infoPostFix: '',
    //     loadingRecords: 'Cargando registros...',
    //     zeroRecords: 'No se encontraron registros',
    //     emptyTable: 'No hay datos disponibles en la tabla',
    //     paginate: {
    //       first: 'Primero',
    //       previous: 'Anterior',
    //       next: 'Siguiente',
    //       last: 'Último',
    //     },
    //     aria: {
    //       sortAscending: ': Activar para ordenar la tabla en orden ascendente',
    //       sortDescending:
    //         ': Activar para ordenar la tabla en orden descendente',
    //     },
    //   },
    // };
  }

  refreshPage() {
    window.location.reload();
  }

  getDetallesPlantel(uniadmClave: number, aluSemestre: number) {
    this.servicio
      .getDatosPlantel(uniadmClave, aluSemestre)
      .subscribe((resp: tipoRespuesta) => {
        this.completados = resp.data[0].completados;
        this.conDocumentosPendientes = resp.data[0].conDocumentosPendientes;
        this.sinDocumentos = resp.data[0].sinDocumentos;
        this.sinRegistro = resp.data[0].sinRegistro;
        this.total = resp.data[0].total;
        this.infoUpd = resp.data;
        this.porCompletados = (this.completados / this.total) * 100;
        this.porConDocumentosPendientes =
          (this.conDocumentosPendientes / this.total) * 100;
        this.porSinDocumentos = (this.sinDocumentos / this.total) * 100;
        this.porSinRegistro = (this.sinRegistro / this.total) * 100;
      });
  }

  getAlumnosPlantel(uniadm: number, aluSemestre: number) {
    this.servicio
      .getAlumnosPlantel(uniadm, aluSemestre)
      .subscribe((resp: tipoRespuesta) => {
        this.infoUpd = resp.data;
        // this.alumnos = resp.data;
        this.alumnos = this.infoUpd.map((elemento) => {
          return elemento;
        });
      });
  }

  verArchivo(archivo: string) {
    // this.router.navigateByUrl('ruta',_bla)
    window.open(`${this.rutaArchivo}/${archivo}`, '_blank');
  }

  validaArchivo(
    matricula: string,
    documento: string,
    status: number,
    motivo: string = ''
  ) {
    Swal.fire({
      title: 'Actualizando Status del Documento',
      icon: 'info',
    });
    Swal.showLoading();
    switch (documento) {
      case 'acta':
        this.servicio
          .updActaStatus(matricula, status, motivo)
          .subscribe((resp: tipoRespuesta) => {
            Swal.close();
            this.actualizaJson(matricula, 'acta', status, motivo);
            this.getDetallesPlantel(this.uniadmClave, this.aluSemestre);
          });
        break;
      case 'curp':
        this.servicio
          .updCurpStatus(matricula, status, motivo)
          .subscribe((resp: tipoRespuesta) => {
            Swal.close();
            this.actualizaJson(matricula, 'curp', status, motivo);
            this.getDetallesPlantel(this.uniadmClave, this.aluSemestre);
          });
        break;
      case 'secuntadia':
        this.servicio
          .updSecundariaStatus(matricula, status, motivo)
          .subscribe((resp: tipoRespuesta) => {
            Swal.close();
            this.actualizaJson(matricula, 'secuntadia', status, motivo);
            this.getDetallesPlantel(this.uniadmClave, this.aluSemestre);
          });
        break;
      case 'foto':
        this.servicio
          .updFotoStatus(matricula, status, motivo)
          .subscribe((resp: tipoRespuesta) => {
            Swal.close();
            this.actualizaJson(matricula, 'foto', status, motivo);
            this.getDetallesPlantel(this.uniadmClave, this.aluSemestre);
          });
        break;
      case 'credencial':
        this.servicio
          .updCredencialStatus(matricula, status, motivo)
          .subscribe((resp: tipoRespuesta) => {
            Swal.close();
            this.actualizaJson(matricula, 'credencial', status, motivo);
            this.getDetallesPlantel(this.uniadmClave, this.aluSemestre);
          });
        break;
      case 'recibo':
        this.servicio
          .updReciboStatus(matricula, status, motivo)
          .subscribe((resp: tipoRespuesta) => {
            Swal.close();
            this.actualizaJson(matricula, 'recibo', status, motivo);
            this.getDetallesPlantel(this.uniadmClave, this.aluSemestre);
          });
        break;
    }
  }

  actualizaJson(
    matricula: string,
    archivo: string,
    status: number,
    motivo: string
  ) {
    let indice = this.alumnos.findIndex((al) => al.aluMatricula === matricula);
    switch (archivo) {
      case 'acta':
        this.alumnos[indice].ald_file_acta_status = status;
        this.alumnos[indice].ald_file_acta_motivo = motivo;
        break;
      case 'curp':
        this.alumnos[indice].ald_file_curp_status = status;
        this.alumnos[indice].ald_file_curp_motivo = motivo;
        break;
      case 'secuntadia':
        this.alumnos[indice].ald_file_secundaria_status = status;
        this.alumnos[indice].ald_file_secundaria_motivo = motivo;
        break;
      case 'foto':
        this.alumnos[indice].ald_file_foto_status = status;
        this.alumnos[indice].ald_file_foto_motivo = motivo;
        break;
      case 'credencial':
        this.alumnos[indice].ald_file_credencial_status = status;
        this.alumnos[indice].ald_file_credencial_motivo = motivo;
        break;
      case 'recibo':
        this.alumnos[indice].ald_file_recibo_status = status;
        this.alumnos[indice].ald_file_recibo_motivo = motivo;
        break;
    }
  }
}
