<div class="card">
  <div class="card-header">Fechas Validas</div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4">
        <button
          type="button"
          routerLink="/controlEscolar"
          class="mb-3 btn btn-outline-info"
        >
          <i class="far fa-arrow-alt-circle-left"></i> Regresar
        </button>
      </div>
    </div>

    <div class="row">
      <h2>Fechas del Proceso 2024-2025</h2>
      <br />
      <div class="col-md-12">
        <ol class="list-group list-group-item">
          <li
            class="list-group-item d-flex justify-content-between align-items-start"
            *ngFor="let f of fechas"
          >
            <div class="ms-2 me-auto">
              <div class="fw-bold">
                {{ f.fecha | date : "dd ' De' MMMM, yyyy" | titlecase }}
                <button
                  (click)="deleteDay(f.fecha)"
                  class="btn btn-sm btn-danger"
                >
                  X
                </button>
              </div>
              <div class="btn-group" role="group">
                <button
                  type="button"
                  [ngClass]="
                    f.letras.includes(l) ? 'btn-primary' : 'btn-outline-primary'
                  "
                  class="btn"
                  (click)="cambiaLetra(f.fecha, l)"
                  *ngFor="let l of LETRAS"
                >
                  {{ l }}
                </button>

                <button class="btn btn-success" (click)="agregarTodas(f.fecha)">
                  TODAS
                </button>
                <button class="btn btn-warning" (click)="quitarTodas(f.fecha)">
                  LIMPIAR
                </button>
              </div>
            </div>
          </li>

          <li
            class="list-group-item d-flex justify-content-between align-items-start"
          >
            <div class="ms-2 me-auto">
              <div class="fw-bold">Agregar Fecha</div>
              <div class="btn-group" role="group">
                <input
                  type="date"
                  name="nuevaFecha"
                  id="nuevaFecha"
                  class="form-control"
                  [(ngModel)]="nuevaFecha"
                />
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="agregaFecha()"
                >
                  Agregar
                </button>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>
