import { Injectable } from '@angular/core';
import { LoginModel } from './usuario.Model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private authUrl = `${environment.AuthUrl}/api/seguridad/loginSGII`;
  logueado: boolean = false;

  constructor(private http: HttpClient, private router: Router) {}

  login(user: LoginModel): any {
    return this.http.post(`${this.authUrl}`, user).pipe(
      map((resp: any) => {
        if (!resp.ok) {
          const info = {
            error: true,
            msg: 'Usuario o Contraseña Incorrecto',
          };
          return info;
        }
        sessionStorage.setItem('logueado', '1');
        sessionStorage.setItem('aluSemestre', '0');
        sessionStorage.setItem('rol', resp['data']['rol']);
        sessionStorage.setItem('rol', resp['data']['rol']);
        sessionStorage.setItem('token', resp.token);
        sessionStorage.setItem('turno', resp['data']['turno']);
        sessionStorage.setItem('turnoId', resp['data']['turnoId']);
        sessionStorage.setItem('usuAdmin', resp['data']['usuAdmin']);
        sessionStorage.setItem('usuSistema', resp['data']['usuSistema']);
        sessionStorage.setItem('uniadmClave', resp['data']['uniadmClave']);
        sessionStorage.setItem('nombreCompleto', resp['data']['nombreCompleto']);
        sessionStorage.setItem('unidadAdministrativa', resp['data']['nombreCompleto']);
        this.logueado = true;
        console.log(resp['data']['rol']);

        return {
          error: false,
          rol: resp['data']['rol'],
          nombre: resp['data']['nombreCompleto'],
          usuAdmin: resp['data']['usuAdmin'],
          usuSistema: resp['data']['usuSistema'],
          uniadmClave: resp['data']['uniadmClave'],
        };
      })
    );
  }

  accesoDenegado(): void {
    Swal.fire({
      icon: 'warning',
      text: 'No tiene acceso a la plataforma',
    });
  }
}
