import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { tipoRespuesta } from '../inscripcion/alumno.interface';

@Injectable({
  providedIn: 'root',
})
export class ControlEscolarService {
  url = `${environment.url}/api/v1`;
  // url = `${environment.url}:${environment}/api/v1`;

  constructor(private http: HttpClient) {}

  getDatosGenerales(uniadm: string) {
    console.log(`${this.url}/alumnoDetalle/getDatosGeneral/${uniadm}`);

    return this.http.get<tipoRespuesta>(
      `${this.url}/alumnoDetalle/getDatosGeneral/${uniadm}`
    );
  }

  getPlantel(uniadm: number) {
    return this.http.get<tipoRespuesta>(
      `${this.url}/alumnoDetalle/getPlantel/${uniadm}`
    );
  }

  getDatosPlantel(uniadm: number, aluSemestre: number) {
    return this.http.get<tipoRespuesta>(
      `${this.url}/alumnoDetalle/getDatosPlantel/${uniadm}`
      // `${this.url}/alumnoDetalle/getDatosPlantel/${uniadm}/${aluSemestre}`
    );
  }

  getAlumnosPlantel(uniadm: number, aluSemestre: number) {
    return this.http.get<tipoRespuesta>(
      `${this.url}/alumnoDetalle/getAlumnoPlantel/${uniadm}`
      // `${this.url}/alumnoDetalle/getAlumnoPlantel/${uniadm}/${aluSemestre}`
    );
  }

  updActaStatus(matricula: string, status: number, motivo: string) {
    let params = {
      matricula: matricula,
      status: status,
      motivo: motivo,
    };
    return this.http.post<tipoRespuesta>(
      `${this.url}/alumnoDetalle/updActaStatus`,
      params
    );
  }

  updCurpStatus(matricula: string, status: number, motivo: string) {
    let params = {
      matricula: matricula,
      status: status,
      motivo: motivo,
    };
    return this.http.post<tipoRespuesta>(
      `${this.url}/alumnoDetalle/updCurpStatus`,
      params
    );
  }

  updSecundariaStatus(matricula: string, status: number, motivo: string) {
    let params = {
      matricula: matricula,
      status: status,
      motivo: motivo,
    };
    return this.http.post<tipoRespuesta>(
      `${this.url}/alumnoDetalle/updSecundariaStatus`,
      params
    );
  }

  updFotoStatus(matricula: string, status: number, motivo: string) {
    let params = {
      matricula: matricula,
      status: status,
      motivo: motivo,
    };
    return this.http.post<tipoRespuesta>(
      `${this.url}/alumnoDetalle/updFotoStatus`,
      params
    );
  }

  updCredencialStatus(matricula: string, status: number, motivo: string) {
    let params = {
      matricula: matricula,
      status: status,
      motivo: motivo,
    };
    return this.http.post<tipoRespuesta>(
      `${this.url}/alumnoDetalle/updCredencialStatus`,
      params
    );
  }

  updReciboStatus(matricula: string, status: number, motivo: string) {
    let params = {
      matricula: matricula,
      status: status,
      motivo: motivo,
    };
    return this.http.post<tipoRespuesta>(
      `${this.url}/alumnoDetalle/updReciboStatus`,
      params
    );
  }
}
