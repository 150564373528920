import { Component } from '@angular/core';
import { Router } from '@angular/router';
// import DataTables from 'datatables.net';
import { tipoRespuesta } from '../inscripcion/alumno.interface';
import { ControlEscolarService } from './control-escolar.service';
import { infoPlantel } from './datos.interface';

@Component({
  selector: 'app-control-escolar',
  templateUrl: './control-escolar.component.html',
  styleUrls: ['./control-escolar.component.css'],
})
export class ControlEscolarComponent {
  completados: number = 0;
  conDocumentosPendientes: number = 0;
  sinDocumentos: number = 0;
  sinRegistro: number = 0;
  total: number = 0;
  unidadAdministrativa: string | undefined;
  aluSemestre: number = 0;

  porCompletados: number | undefined;
  porConDocumentosPendientes: number | undefined;
  porSinDocumentos: number | undefined;
  porSinRegistro: number | undefined;
  token = sessionStorage.getItem('token');
  informacion: infoPlantel[] = [];
  // dtOptions: DataTables.Settings = {};

  rol = sessionStorage.getItem('rol');
  uniAdm = sessionStorage.getItem('uniadmClave')!;
  usuLogin = sessionStorage.getItem('usuLogin')

  constructor(
    private _servicio: ControlEscolarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.login();
    this.getDatosGenerales(this.uniAdm);
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 25,
    //   processing: true,
    //   language: {
    //     processing: 'Procesando...',
    //     search: 'Buscar:',
    //     lengthMenu: 'Mostrar _MENU_ registros',
    //     info: 'Mostrando desde _START_ al _END_ de _TOTAL_ registros',
    //     infoEmpty: 'Mostrando ningún registro.',
    //     infoFiltered: '(filtrado _MAX_ registro total)',
    //     infoPostFix: '',
    //     loadingRecords: 'Cargando registros...',
    //     zeroRecords: 'No se encontraron registros',
    //     emptyTable: 'No hay datos disponibles en la tabla',
    //     paginate: {
    //       first: 'Primero',
    //       previous: 'Anterior',
    //       next: 'Siguiente',
    //       last: 'Último',
    //     },
    //     aria: {
    //       sortAscending: ': Activar para ordenar la tabla en orden ascendente',
    //       sortDescending:
    //         ': Activar para ordenar la tabla en orden descendente',
    //     },
    //   },
    // };
  }

  login() {
    if (this.token == null) {
      this.router.navigateByUrl('/login');
    }
  }

  refreshPage() {
    window.location.reload();
  }

  getDatosGenerales(uniAdm: string) {
    let unidadAdministrativa;
    if (this.rol == 'CONTROL ESCOLAR') {
      unidadAdministrativa = uniAdm;
    } else if (this.rol == 'DIRECTOR') {
      unidadAdministrativa = uniAdm;
    } else if (this.rol == 'SUBDIRECTOR') {
      unidadAdministrativa = uniAdm;
    } else if (this.rol == 'RESPONSABLE EMSAD') {
      unidadAdministrativa = uniAdm;
    } else if (this.rol == 'SUBDIRECTOR EMSAD') {
      unidadAdministrativa = uniAdm;
    } else {
      unidadAdministrativa = '0';
    }
    this._servicio.getDatosGenerales(unidadAdministrativa).subscribe((resp: tipoRespuesta) => {
        this.informacion = resp.data;
        resp.data.map((datos:any) => {
          this.completados += datos.completados;
          this.conDocumentosPendientes += datos.conDocumentosPendientes;
          this.sinDocumentos += datos.sinDocumentos;
          this.sinRegistro += datos.sinRegistro;
          this.total += datos.total;
        });
        this.porCompletados = (this.completados / this.total) * 100;
        this.porConDocumentosPendientes = (this.conDocumentosPendientes / this.total) * 100;
        this.porSinDocumentos = (this.sinDocumentos / this.total) * 100;
        this.porSinRegistro = (this.sinRegistro / this.total) * 100;
      });
  }
  verDetallesPlantel(
    uniadmClave: number,
    unidadAdministrativa: string,
    aluSemestre: string
  ) {
    sessionStorage.setItem('uniadmClave', uniadmClave.toString());
    sessionStorage.setItem('unidadAdministrativa', unidadAdministrativa);
    sessionStorage.setItem('aluSemestre', aluSemestre);
    this.router.navigateByUrl('/planteles');
  }
}
