import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ControlEscolarComponent } from './control-escolar/control-escolar.component';
import { InscripcionComponent } from './inscripcion/inscripcion.component';
import { LoginComponent } from './login/login.component';
import { PlantelesComponent } from './planteles/planteles.component';
import { FechasComponent } from './fechas/fechas.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'fechas', component: FechasComponent },
  { path: 'planteles', component: PlantelesComponent },
  { path: 'inscripcion', component: InscripcionComponent },
  { path: 'controlEscolar', component: ControlEscolarComponent },
  { path: '', pathMatch: 'full', redirectTo: 'inscripcion' },
  { path: '**', pathMatch: 'full', redirectTo: 'inscripcion' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
