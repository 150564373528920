import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import {
  Alumno,
  TipoDiscapacidades,
  Discapacidad,
  tipoRespuesta,
} from './alumno.interface';
import { InscripcionService } from './inscripcion.service';
import { FechasService } from '../fechas/fechas.service';
import { FechaModificable } from '../registro/interfaces';

@Component({
  selector: 'app-inscripcion',
  templateUrl: './inscripcion.component.html',
  styleUrls: ['./inscripcion.component.css'],
})
export class InscripcionComponent {
  tiempoActual = new Date().getTime();
  fechaComparar = new Date('2023-08-07').getTime();
  hoy = new Date();
  year = this.hoy.getFullYear();
  fechas: FechaModificable[] = [];
  activacion = false;
  periodoActual = '25A';
  title = 'Inscripciones';
  fechaHoy = '';
  periodo = '2024-2025A';
  encontrado = false;
  modalidadEstudio = 1;
  servicioMedico = 0;
  zonaIndigena = 0;
  lenguaIndigena = 0;
  curp = '';
  datosAlumno!: Alumno;
  archivoSubir!: File;
  tipoSubir!: string;
  lineaCaptura: number = 0;
  tieneDiscapacidad!: boolean;
  tipoDiscapacidades: TipoDiscapacidades[] = [];
  discapacidades: Discapacidad[] = [];
  alTipoDiscapacidad = '';
  alDiscapacidad = '';
  discapacidadesAlumno: any[] = [];
  disAlTipoId!: number;
  disAlTipo!: string;
  disAlDiscId!: number;
  disAlDisc!: string;
  tieneDetalles: boolean = false;
  urlFile = `${environment.urlFile}/api/upload/archivo`;
  desactivarBoton: boolean = false;

  rutaActaNacimiento: string = '';
  rutaCURP: string = '';
  rutaCertSecundaria: string = '';
  rutaFotografia: string = '';
  rutaCredencial: string = '';
  rutaRecibo: string = '';
  lineaDeCaptura: number = 0;

  aldFileActaDate!: string;
  aldFileCredencialDate!: string;
  aldFileCurpDate!: string;
  aldFileFotoDate!: string;
  aldFileReciboDate!: string;
  aldFileSecundariaDate!: string;
  aldFileActaMotivo!: string;

  aldFileActaStatus!: number;
  aldFileCredencialStatus!: number;
  aldFileCurpStatus!: number;
  aldFileFotoStatus!: number;
  aldFileReciboStatus!: number;
  aldFileSecundariaStatus!: number;

  aldFileActaStatusDesc!: string;
  aldFileCredencialStatusDesc!: string;
  aldFileCurpStatusDesc!: string;
  aldFileFotoStatusDesc!: string;
  aldFileReciboStatusDesc!: string;
  aldFileSecundariaStatusDesc!: string;

  archivoSubidoCurp = false;
  archivoSubidoINE = false;
  aldFileINEStatus!: number;
  rutaINE: string = '';

  letrasHoy: string[] = [];
  clickGuarda = false;

  constructor(
    private _srvinscripcion: InscripcionService,
    private srvFechas: FechasService
  ) {}

  ngOnInit(): void {
    if (this.tiempoActual > this.fechaComparar) {
      console.log('La fecha ya paso.');
      this.activacion = true;
    } else {
      console.log('La fecha aún no ha pasado.');
      this.activacion = false;
    }
    const today = new Date();
    const diaActual = today.getDate();
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    this.fechaHoy = today.toLocaleDateString('es-MX');
    this.getTipoDiscapacidades();
    this.getFechasYear();
    // this.encuentralo();
  }

  reemplazarCaracter(char: string) {
    switch (char) {
      case 'Á':
        return 'A';
      case 'É':
        return 'E';
      case 'Í':
        return 'I';
      case 'Ó':
        return 'O';
      case 'Ú':
        return 'U';
      default:
        return char;
    }
  }

  consultaLetrasDelDia() {
    console.log('consultaLetrasDelDia');
    this.fechas.forEach((dato) => {
      let fecha = new Date(dato.fecha);
      console.log('Hoy es ', this.fechaHoy);
      console.log(fecha.toLocaleDateString('es-MX'));

      if (this.fechaHoy == fecha.toLocaleDateString('es-MX')) {
        console.log('Es Hoy!!');
        this.letrasHoy = dato.letras;
      } else {
        console.log('NO ES HOY');
      }

      if (this.letrasHoy.length == 0) {
        console.log('Hoy NO hay letras disponibles');
      } else {
        console.log('Las letras de hoy son ', this.letrasHoy);
      }
    });
  }

  getFechasYear() {
    this.srvFechas.getFechasYear(this.year).subscribe({
      next: (resp: any) => {
        resp.data.forEach((element: any) => {
          const arregloFecha = element.fecha.split('/');
          const dia = arregloFecha[0];
          const mes = arregloFecha[1] - 1;
          const anio = arregloFecha[2];

          element.fecha = new Date(anio, mes, dia);
          if (element.letras == null) {
            element.letras = [];
          } else {
            element.letras = element.letras.split('');
            element.letras = element.letras.map(this.reemplazarCaracter);
            // const dataArr = new Set(element.letras);
            // let result = [...dataArr];
            let result = Array.from(new Set(element.letras));
            element.letras = result;
          }
        });
        this.fechas = resp.data;
        this.consultaLetrasDelDia();
        // console.log(this.fechas);
      },
      error: (error: any) => {},
    });
  }

  dimeDis() {
    console.log('Discapacidad => ', this.tieneDiscapacidad);
  }

  setModalidadEstudio(modalidad: number) {
    this.modalidadEstudio = modalidad;
  }

  setServicioMEdico(servicio: number) {
    this.servicioMedico = servicio;
  }

  setZonaIndigena(zona: number) {
    this.zonaIndigena = zona;
  }

  setLenguaIndigena(lengua: number) {
    this.lenguaIndigena = lengua;
  }

  encuentralo() {
    this.limpia();
    if (this.curp.length < 18) {
      return;
    }

    // console.log(`La curps es ${this.curp[0]}`);
    let diasActuales = this.letrasHoy;
    console.log(diasActuales);
    console.log(diasActuales.includes(this.curp[0].toUpperCase()));

    if (diasActuales.includes(this.curp[0].toUpperCase())) {
      console.log('si le toca hoy');
    } else {
      Swal.fire({
        title: `Hoy no le corresponde, las letras del dia de hoy son ${this.letrasHoy}`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 2000,
      });
      return;
    }

    Swal.fire({
      title: 'Buscando alumno...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 3000,
    });
    Swal.showLoading();
    this._srvinscripcion
      .buscaAlumno(this.curp)
      .subscribe((resp: tipoRespuesta) => {
        Swal.close();
        if (resp.error == false) {
          this.datosAlumno = resp.data[0];
          this.encontrado = true;
          this.getAlumnoDetalles(this.datosAlumno.aluMatricula);
        } else {
          this.encontrado = false;
          // this.curp = '';
          Swal.fire({
            title: 'Alumno no encontrado...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 2000,
          });
        }
      });
  }

  getAlumnoDetalles(matricula: string) {
    this._srvinscripcion
      .getAlumnoDetalles(matricula)
      .subscribe((resp: tipoRespuesta) => {
        if (resp.data.length > 0) {
          this.tieneDetalles = true;
          this.getDiscapacidadesAlumno(matricula);
          this.setZonaIndigena(resp.data[0].aldZonaIndigena);
          this.setServicioMEdico(resp.data[0].aldServicioMedico);
          this.setLenguaIndigena(resp.data[0].aldLenguaIndigena);
          this.setModalidadEstudio(resp.data[0].aldModalidad);

          if (resp.data[0].aldFileActa != null) {
            this.rutaActaNacimiento = `${this.urlFile}/${resp.data[0].aldFileActa}`;
          }
          if (resp.data[0].aldFileCurp != null) {
            this.rutaCURP = `${this.urlFile}/${resp.data[0].aldFileCurp}`;
          }
          if (resp.data[0].aldFileSecuntadia != null) {
            this.rutaCertSecundaria = `${this.urlFile}/${resp.data[0].aldFileSecuntadia}`;
          }
          if (resp.data[0].aldFileFoto != null) {
            this.rutaFotografia = `${this.urlFile}/${resp.data[0].aldFileFoto}`;
          }
          if (resp.data[0].aldFileCredencial != null) {
            this.rutaCredencial = `${this.urlFile}/${resp.data[0].aldFileCredencial}`;
          }
          if (resp.data[0].aldFileRecibo != null) {
            this.rutaRecibo = `${this.urlFile}/${resp.data[0].aldFileRecibo}`;
          }
          if (resp.data[0].aldLineaCaptura != null) {
            this.lineaDeCaptura = resp.data[0].aldLineaCaptura;
            this.lineaCaptura = resp.data[0].aldLineaCaptura;
          }
          if (resp.data[0].aldFileActaDate != null) {
            this.aldFileActaDate = resp.data[0].aldFileActaDate;
          }
          if (resp.data[0].aldFileCredencialDate != null) {
            this.aldFileCredencialDate = resp.data[0].aldFileCredencialDate;
          }
          if (resp.data[0].aldFileCurpDate != null) {
            this.aldFileCurpDate = resp.data[0].aldFileCurpDate;
          }
          if (resp.data[0].aldFileFotoDate != null) {
            this.aldFileFotoDate = resp.data[0].aldFileFotoDate;
          }
          if (resp.data[0].aldFileReciboDate != null) {
            this.aldFileReciboDate = resp.data[0].aldFileReciboDate;
          }
          if (resp.data[0].aldFileSecundariaDate != null) {
            this.aldFileSecundariaDate = resp.data[0].aldFileSecundariaDate;
          }
          if (resp.data[0].aldFileActaStatus != null) {
            this.aldFileActaStatus = resp.data[0].aldFileActaStatus;
          }
          if (resp.data[0].aldFileCredencialStatus != null) {
            this.aldFileCredencialStatus = resp.data[0].aldFileCredencialStatus;
          }
          if (resp.data[0].aldFileCurpStatus != null) {
            this.aldFileCurpStatus = resp.data[0].aldFileCurpStatus;
          }
          if (resp.data[0].aldFileFotoStatus != null) {
            this.aldFileFotoStatus = resp.data[0].aldFileFotoStatus;
          }
          if (resp.data[0].aldFileReciboStatus != null) {
            this.aldFileReciboStatus = resp.data[0].aldFileReciboStatus;
          }
          if (resp.data[0].aldFileSecundariaStatus != null) {
            this.aldFileSecundariaStatus = resp.data[0].aldFileSecundariaStatus;
          }
          if (resp.data[0].aldFileActaStatusDesc != null) {
            this.aldFileActaStatusDesc = resp.data[0].aldFileActaStatusDesc;
          }
          if (resp.data[0].aldFileCredencialStatusDesc != null) {
            this.aldFileCredencialStatusDesc =
              resp.data[0].aldFileCredencialStatusDesc;
          }
          if (resp.data[0].aldFileCurpStatusDesc != null) {
            this.aldFileCurpStatusDesc = resp.data[0].aldFileCurpStatusDesc;
          }
          if (resp.data[0].aldFileFotoStatusDesc != null) {
            this.aldFileFotoStatusDesc = resp.data[0].aldFileFotoStatusDesc;
          }
          if (resp.data[0].aldFileReciboStatusDesc != null) {
            this.aldFileReciboStatusDesc = resp.data[0].aldFileReciboStatusDesc;
          }
          if (resp.data[0].aldFileSecundariaStatusDesc != null) {
            this.aldFileSecundariaStatusDesc =
              resp.data[0].aldFileSecundariaStatusDesc;
          }
        } else {
          this.tieneDetalles = false;
        }
      });
  }

  getDiscapacidadesAlumno(matricula: string) {
    this._srvinscripcion
      .getAlumnoDiscapacidades(matricula)
      .subscribe((resp: tipoRespuesta) => {
        resp.data.length > 0
          ? (this.tieneDiscapacidad = true)
          : (this.tieneDiscapacidad = false);
        resp.data.forEach((el: any) => {
          this.discapacidadesAlumno.push({
            disAlDisc: el.dscDescripcion,
            disAlDiscId: el.adiDscId,
            disAlTipo: el.tdiDescripcion,
            disAlTipoId: el.dscTdiId,
          });
        });
      });
  }

  termina() {
    // this.clickGuarda = true;
    this._srvinscripcion
      .guardaAlumnoDetalles(
        this.datosAlumno.aluMatricula,
        this.zonaIndigena,
        this.servicioMedico,
        this.modalidadEstudio,
        this.lenguaIndigena
      )
      .subscribe((resp: tipoRespuesta) => {
        this._srvinscripcion
          .guardaAlumnoDiscapacidades(
            this.discapacidadesAlumno,
            this.datosAlumno.aluMatricula
          )
          .subscribe((resp: tipoRespuesta) => {
            Swal.fire({
              title: 'Información Registrada',
              icon: 'success',
              timer: 2000,
            });
            this.getAlumnoDetalles(this.datosAlumno.aluMatricula);
          });
      });
  }

  dimeSM(e: any) {
    console.log('e.target.value => ', e.target.value);
    this.servicioMedico = e.target.value;
  }

  getTipoDiscapacidades() {
    this._srvinscripcion
      .getTipoDiscapacidades()
      .subscribe((resp: tipoRespuesta) => {
        this.tipoDiscapacidades = resp.data;
      });
  }

  getDiscapacidad() {
    console.log(this.alTipoDiscapacidad);
    let valor = this.alTipoDiscapacidad;
    const data = valor.split('/');
    const id = parseInt(data[0]);
    const tipoDiscapacidad = data[1];
    this.disAlTipoId = id;
    this.disAlTipo = tipoDiscapacidad;
    this._srvinscripcion
      .getDiscapacidades(id)
      .subscribe((resp: tipoRespuesta) => {
        this.discapacidades = resp.data;
      });
  }

  revisaDAtos() {
    const data = {
      disAlTipoId: this.disAlTipoId,
      disAlTipo: this.disAlTipo,
      disAlDiscId: this.disAlDiscId,
      disAlDisc: this.disAlDisc,
    };

    console.log(data);

    if (this.disAlTipoId == 0 || this.disAlDiscId == 0) {
      return;
    }

    this.discapacidadesAlumno.push(data);
    this.disAlTipoId = 0;
    this.disAlTipo = '';
    this.disAlDiscId = 0;
    this.disAlDisc = '';
    this.discapacidades = [];
    this.alTipoDiscapacidad = '';
  }

  updDiscapacidad() {
    let valor = this.alDiscapacidad;
    const data = valor.split('/');
    const id = parseInt(data[0]);
    const discapacidad = data[1];
    this.disAlDiscId = id;
    this.disAlDisc = discapacidad;
  }

  eliminaDiscapacidadAlumno(indice: number) {
    this.discapacidadesAlumno.splice(indice, 1);
  }

  agarraArchivo(archivo: any, tipoSubir: string) {
    // 3 Mb
    // this.archivoSubir = archivo.target.files[0];
    let archivoTemporal = archivo.target.files[0];
    let maxfilesize = 1024 * 2024;
    let filesize = archivoTemporal.size;
    let warningel = document.getElementById('lbError')!;

    if (filesize > maxfilesize) {
      Swal.fire({
        title:
          'El archivo excede  el tamaño Maximo Permitido de: ' +
          (maxfilesize / 1024).toFixed(2) +
          'KB.  <br/> Tamaño del archivo: ' +
          (filesize / 1024).toFixed(2) +
          ' KB.',
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      warningel.innerHTML = '';
      return false;
    } else {
      warningel.innerHTML = '';
      this.tipoSubir = tipoSubir;
      this.archivoSubir = archivoTemporal;
      console.log(this.tipoSubir);
      console.log(this.archivoSubir);
      // this.subeArchivo(tipoSubir);
      return true;
    }
  }

  subeArchivo(nombreSubir: string) {
    Swal.fire({
      title: 'Subiendo archivo...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();
    let rutaSubir = `${this.periodoActual},${this.datosAlumno.aluMatricula}`;
    let nombreDelARchivo = nombreSubir;
    if (nombreSubir == 'fotografia') {
      nombreSubir = this.datosAlumno.aluMatricula;
    }
    this._srvinscripcion
      .actualizaArchivo(this.archivoSubir, rutaSubir, nombreSubir)
      .subscribe(
        (resp: any) => {
          Swal.close();
          switch (nombreDelARchivo) {
            case 'actaNacimiento':
              this.rutaActaNacimiento = `${this.urlFile}/${resp.rutaArchivo}`;
              this._srvinscripcion
                .updFileActa(this.datosAlumno.aluMatricula, resp.rutaArchivo)
                .subscribe((resp: tipoRespuesta) => {
                  Swal.fire({
                    title: 'Archivo Subido',
                    icon: 'success',
                    timer: 2000,
                  });
                  this.getAlumnoDetalles(this.datosAlumno.aluMatricula);
                });
              break;
            case 'CURP':
              this.rutaCURP = `${this.urlFile}/${resp.rutaArchivo}`;
              this._srvinscripcion
                .updFileCurp(this.datosAlumno.aluMatricula, resp.rutaArchivo)
                .subscribe((resp: tipoRespuesta) => {
                  Swal.fire({
                    title: 'Archivo Subido',
                    icon: 'success',
                    timer: 2000,
                  });
                  this.getAlumnoDetalles(this.datosAlumno.aluMatricula);
                });
              break;
            case 'certSecundaria':
              this.rutaCertSecundaria = `${this.urlFile}/${resp.rutaArchivo}`;
              this._srvinscripcion
                .updFileSecundaria(
                  this.datosAlumno.aluMatricula,
                  resp.rutaArchivo
                )
                .subscribe((resp: tipoRespuesta) => {
                  Swal.fire({
                    title: 'Archivo Subido',
                    icon: 'success',
                    timer: 2000,
                  });
                  this.getAlumnoDetalles(this.datosAlumno.aluMatricula);
                });
              break;
            case 'fotografia':
              this.rutaFotografia = `${this.urlFile}/${resp.rutaArchivo}`;
              this._srvinscripcion
                .updFileFoto(this.datosAlumno.aluMatricula, resp.rutaArchivo)
                .subscribe((resp: tipoRespuesta) => {
                  Swal.fire({
                    title: 'Archivo Subido',
                    icon: 'success',
                    timer: 2000,
                  });
                  this.getAlumnoDetalles(this.datosAlumno.aluMatricula);
                });
              break;
            case 'credencial':
              this.rutaCredencial = `${this.urlFile}/${resp.rutaArchivo}`;
              this._srvinscripcion
                .updFileCredencial(
                  this.datosAlumno.aluMatricula,
                  resp.rutaArchivo
                )
                .subscribe((resp: tipoRespuesta) => {
                  Swal.fire({
                    title: 'Archivo Subido',
                    icon: 'success',
                    timer: 2000,
                  });
                  this.getAlumnoDetalles(this.datosAlumno.aluMatricula);
                });
              break;
            case 'ineTutor':
              this.rutaINE = `${this.urlFile}/${resp.rutaArchivo}`;
              this._srvinscripcion
                .updFileCredencial(
                  this.datosAlumno.aluMatricula,
                  resp.rutaArchivo
                )
                .subscribe((resp: tipoRespuesta) => {
                  Swal.fire({
                    title: 'Archivo Subido',
                    icon: 'success',
                    timer: 2000,
                  });
                  this.getAlumnoDetalles(this.datosAlumno.aluMatricula);
                });
              break;
            case 'recibo':
              this.rutaRecibo = `${this.urlFile}/${resp.rutaArchivo}`;
              this._srvinscripcion
                .updFileRecibo(this.datosAlumno.aluMatricula, resp.rutaArchivo)
                .subscribe((resp: tipoRespuesta) => {
                  Swal.fire({
                    title: 'Archivo Subido',
                    icon: 'success',
                    timer: 2000,
                  });
                  this.getAlumnoDetalles(this.datosAlumno.aluMatricula);
                });
              break;
          }
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Error al subir el archivo, intente mas tarde.',
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
        }
      );
  }

  guardaLineaCaptura() {
    if (this.lineaCaptura != 0) {
      this._srvinscripcion
        .updLineaCaptura(this.datosAlumno.aluMatricula, this.lineaCaptura)
        .subscribe((resp: tipoRespuesta) => {
          Swal.fire({
            title: 'Linea de Captura Guardada',
            icon: 'success',
            timer: 2000,
          });
          this.lineaDeCaptura = this.lineaCaptura;
        });
    }
  }

  cerrar() {
    this.encontrado = false;
    this.tieneDetalles = false;
    this.curp = '';
    this.desactivarBoton = true;
  }

  limpia() {
    this.discapacidadesAlumno = [];
    this.tieneDiscapacidad = false;
    this.encontrado = false;
    this.modalidadEstudio = 1;
    this.servicioMedico = 0;
    this.zonaIndigena = 0;
    this.lenguaIndigena = 0;
  }
}
