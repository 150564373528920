<div class="card">
  <div class="card-header"><strong>Control Escolar</strong></div>
  <div class="card-body">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <button
              type="button"
              routerLink="/login"
              class="mb-3 btn btn-outline-info">
              <i class="fas fa-sign-out-alt"></i> Salir
            </button>
          </div>
          <div class="col-md-4">
            <button *ngIf="usuLogin == '8311' || usuLogin == 'JUHECA' || usuLogin=='IALVAREZR'"
              type="button"
              routerLink="/fechas"
              class="btn btn-outline-info"
            >
              Fechas
            </button>
          </div>
          <div class="col-md-4">
            <button
              type="button"
              (click)="refreshPage()"
              class="mb-3 btn btn-outline-info"
            >
              <i class="fas fa-sign-out-alt"></i> Actualizar
            </button>
          </div>
        </div>

        <div class="form-group row">
          <label for="staticEmail" class="col-sm-3 col-form-label"
            ><strong>Documentacion Completa:</strong></label
          >
          <div class="col-sm-3">
            <input
              type="text"
              readonly
              class="form-control-plaintext bg-success text-white text-center"
              id="staticEmail"
              [value]="completados"
            />
          </div>

          <label for="staticEmail" class="col-sm-3 col-form-label"
            ><strong>Documentacion en Validación:</strong></label
          >
          <div class="col-sm-3">
            <input
              type="text"
              readonly
              class="form-control-plaintext bg-info text-white text-center"
              id="staticEmail"
              [value]="conDocumentosPendientes"
            />
          </div>

          <label for="staticEmail" class="col-sm-3 col-form-label"
            ><strong>Sin Documentos:</strong></label
          >
          <div class="col-sm-3">
            <input
              type="text"
              readonly
              class="form-control-plaintext bg-warning text-center"
              id="staticEmail"
              [value]="sinDocumentos"
            />
          </div>

          <label for="staticEmail" class="col-sm-3 col-form-label"
            ><strong>Sin Registro:</strong></label
          >
          <div class="col-sm-3">
            <input
              type="text"
              readonly
              class="form-control-plaintext bg-danger text-white text-center"
              id="staticEmail"
              [value]="sinRegistro"
            />
          </div>

          <label for="staticEmail" class="col-sm-3 col-form-label"
            ><strong>Total de Alumnos:</strong></label
          >
          <div class="col-sm-3">
            <input
              type="text"
              readonly
              class="form-control-plaintext text-center"
              id="staticEmail"
              [value]="total"
            />
          </div>
        </div>

        <div class="progress">
          <div
            class="progress-bar bg-success"
            role="progressbar"
            style="width: {{ porCompletados }}%"
          ></div>
          <div
            class="progress-bar bg-info"
            role="progressbar"
            style="width: {{ porConDocumentosPendientes }}%"
          ></div>
          <div
            class="progress-bar bg-warning"
            role="progressbar"
            style="width: {{ porSinDocumentos }}%"
          ></div>
          <div
            class="progress-bar bg-danger"
            role="progressbar"
            style="width: {{ porSinRegistro }}%"
          ></div>
        </div>
      </div>
    </div>
    <div class="suggestion" *ngIf="informacion.length > 0">
      <table class="table table-sm table-bordered table-hover">
        <!-- <table  datatable [dtOptions]="dtOptions" class='table table-sm table-bordered table-hover'> -->
        <thead>
          <tr>
            <th>Plantel</th>
            <th>Semestre</th>
            <th>Completados</th>
            <th>Con Documentos Pendientes</th>
            <th>Sin Documentos</th>
            <th>Sin Registro</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let plan of informacion">
            <td
              class="_cursor"
              (click)="
                verDetallesPlantel(
                  plan.uniadmClave,
                  plan.unidadAdministrativa,
                  plan.aluSemestre
                )
              "
            >
              {{ plan.unidadAdministrativa }}
            </td>
            <td>{{ plan.aluSemestre }}</td>
            <td>{{ plan.completados }}</td>
            <td>{{ plan.conDocumentosPendientes }}</td>
            <td>{{ plan.sinDocumentos }}</td>
            <td>{{ plan.sinRegistro }}</td>
            <td>{{ plan.total }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
