import { Component } from '@angular/core';
import { RegistroService } from './registro.service';

interface Plantel {
  uniadmDesc: string;
  uniadmClave: number;
}

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css'],
})
export class RegistroComponent {
  planteles: Plantel[] = [];

  constructor(private _rSrv: RegistroService) {}

  ngOnInit(): void {
    this._rSrv.getPlanteles().subscribe((resp: any) => {
      this.planteles = resp.data;
      this.planteles.map((pl) => {
        let temp = pl.uniadmDesc.split(' ');
        pl.uniadmDesc = temp[0] + ' ' + temp[1] + ' ' + temp[2];
      });
    });
  }
}
