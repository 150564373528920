<div class="card">
  <div class="card-header">
    <strong>{{ nombreCompleto }} </strong>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-3 col-sm-3">
        <button
          routerLink="/controlEscolar"
          class="mb-3 btn btn-outline-info"
          *ngIf="rol == 'SISTEMA'"
        >
          <i class="far fa-arrow-alt-circle-left"></i>&nbsp;Regresar
        </button>
        <button
          routerLink="/controlEscolar"
          class="mb-3 btn btn-outline-info"
          *ngIf="rol == 'CONTROL ESCOLAR'"
        >
          <i class="far fa-arrow-alt-circle-left"></i>&nbsp;Regresar
        </button>
        <button
          routerLink="/controlEscolar"
          class="mb-3 btn btn-outline-info"
          *ngIf="rol == 'ADMON CONTROL ESCOLAR'"
        >
          <i class="far fa-arrow-alt-circle-left"></i>&nbsp;Regresar
        </button>
      </div>
      <div class="col-md-3 col-sm-3"></div>
      <div class="col-md-3 col-sm-3">
        <button routerLink="/login" class="mb-3 btn btn-outline-info">
          <i class="fas fa-sign-out-alt"></i>&nbsp;Salir
        </button>
      </div>
      <div class="col-md-3 col-sm-3">
        <button
          (click)="refreshPage()"
          class="mb-3 btn btn-outline-primary"
          *ngIf="rol == 'SISTEMA'"
        >
          <i class="far fa-arrow-alt-circle-left"></i>&nbsp;Actualizar
        </button>
      </div>
    </div>
    <div class="card mb-3 mt-2">
      <div class="card-body">
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-3 col-form-label"
            ><strong>Documentacion Completa:</strong></label
          >
          <div class="col-sm-3">
            <input
              type="text"
              readonly
              class="form-control-plaintext bg-success text-white text-center"
              id="staticEmail"
              [value]="completados"
            />
          </div>
          <label for="staticEmail" class="col-sm-3 col-form-label"
            ><strong>Documentacion en Validación:</strong></label
          >
          <div class="col-sm-3">
            <input
              type="text"
              readonly
              class="form-control-plaintext bg-info text-white text-center"
              id="staticEmail"
              [value]="conDocumentosPendientes"
            />
          </div>
          <label for="staticEmail" class="col-sm-3 col-form-label"
            ><strong>Sin Documentos:</strong></label
          >
          <div class="col-sm-3">
            <input
              type="text"
              readonly
              class="form-control-plaintext bg-warning text-center"
              id="staticEmail"
              [value]="sinDocumentos"
            />
          </div>
          <label for="staticEmail" class="col-sm-3 col-form-label"
            ><strong>Sin Registro:</strong></label
          >
          <div class="col-sm-3">
            <input
              type="text"
              readonly
              class="form-control-plaintext bg-danger text-white text-center"
              id="staticEmail"
              [value]="sinRegistro"
            />
          </div>
          <label for="staticEmail" class="col-sm-3 col-form-label"
            ><strong>Total de Alumnos:</strong></label
          >
          <div class="col-sm-3">
            <input
              type="text"
              readonly
              class="form-control-plaintext text-center"
              id="staticEmail"
              [value]="total"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="progress mb-3">
      <div
        class="progress-bar bg-success"
        role="progressbar"
        style="width: {{ porCompletados }}%"
      ></div>
      <div
        class="progress-bar bg-info"
        role="progressbar"
        style="width: {{ porConDocumentosPendientes }}%"
      ></div>
      <div
        class="progress-bar bg-warning"
        role="progressbar"
        style="width: {{ porSinDocumentos }}%"
      ></div>
      <div
        class="progress-bar bg-danger"
        role="progressbar"
        style="width: {{ porSinRegistro }}%"
      ></div>
    </div>

    <div class="suggestion" *ngIf="alumnos.length > 0">
      <table class="table table-sm table-bordered table-hover">
        <!-- <table datatable [dtOptions]="dtOptions" class='table table-sm table-bordered table-hover'> -->
        <thead>
          <tr>
            <th>Matricula</th>
            <th>Semestre</th>
            <th>Nombre del Alumno</th>
            <!-- <th>Acta de nacimiento</th> -->
            <th>Curp</th>
            <!-- <th>Certificado de Secundaria</th> -->
            <!-- <th>Fotografia</th> -->
            <th>Credencial INE del Tutor</th>
            <!-- <th>Recibo</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let al of alumnos">
            <td>{{ al.aluMatricula }}</td>
            <td>{{ al.aluSemestre }}</td>
            <td>{{ al.aluNombre }} {{ al.aluPaterno }} {{ al.aluMaterno }}</td>
            <!-- <td align="center">
              <span *ngIf="al.ald_file_acta_status == 0" class="badge badge-secondary">Sin Archivo</span>
              <span *ngIf="al.ald_file_acta_status == 1" class="badge badge-info">Subido</span>
              <span *ngIf="al.ald_file_acta_status == 2" class="badge badge-warning">A Revisión</span>
              <span *ngIf="al.ald_file_acta_status == 3" class="badge badge-success">Aceptado</span>
              <br />
              <div class="btn-group" role="group" aria-label="Basic example" *ngIf="al.aldFileActa != ''">
                <button type="button" class="btn btn-info" (click)="verArchivo(al.aldFileActa)"><i class="far fa-eye"></i></button>
                <button type="button" class="btn btn-success" (click)="validaArchivo(al.aluMatricula, 'acta', 3)"><i class="fas fa-check-circle"></i></button>
                <button type="button" class="btn btn-danger" (click)="validaArchivo(al.aluMatricula, 'acta', 2)"><i class="far fa-times-circle"></i></button>
              </div>
            </td> -->
            <td align="center">
              <span *ngIf="al.ald_file_curp_status == 0" class="badge bg-secondary" >Sin Archivo</span>
              <span *ngIf="al.ald_file_curp_status == 1" class="badge bg-info" >Subido</span>
              <span *ngIf="al.ald_file_curp_status == 2" class="badge bg-warning" >A Revisión</span>
              <span *ngIf="al.ald_file_curp_status == 3" class="badge bg-success" >Aceptado</span>
              <br />
              <div class="btn-group" role="group" aria-label="Basic example" *ngIf="al.ald_file_curp != ''">
                <button type="button" *ngIf="al.ald_file_curp_status != 0" class="btn btn-info" (click)="verArchivo(al.ald_file_curp)"><i class="fa fa-eye"></i></button>
                <button type="button" *ngIf="al.ald_file_curp_status != 0" class="btn btn-success" (click)="validaArchivo(al.aluMatricula, 'curp', 3)"><i class="fa fa-check-circle"></i></button>
                <button type="button" *ngIf="al.ald_file_curp_status != 0" class="btn btn-danger" (click)="validaArchivo(al.aluMatricula, 'curp', 2)"><i class="fa fa-times-circle"></i></button>
              </div>
            </td>
            <!-- <td align="center">
              <span
                *ngIf="al.ald_file_secundaria_status == 0"
                class="badge badge-secondary"
                >Sin Archivo</span
              >
              <span
                *ngIf="al.ald_file_secundaria_status == 1"
                class="badge badge-info"
                >Subido</span
              >
              <span
                *ngIf="al.ald_file_secundaria_status == 2"
                class="badge badge-warning"
                >A Revisión</span
              >
              <span
                *ngIf="al.ald_file_secundaria_status == 3"
                class="badge badge-success"
                >Aceptado</span
              >
              <br />
              <div
                class="btn-group"
                role="group"
                aria-label="Basic example"
                *ngIf="al.aldFileSecuntadia != ''"
              >
                <button
                  type="button"
                  class="btn btn-info"
                  (click)="verArchivo(al.aldFileSecuntadia)"
                >
                  <i class="far fa-eye"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  (click)="validaArchivo(al.aluMatricula, 'secuntadia', 3)"
                >
                  <i class="fas fa-check-circle"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="validaArchivo(al.aluMatricula, 'secuntadia', 2)"
                >
                  <i class="far fa-times-circle"></i>
                </button>
              </div>
            </td> -->
            <!-- <td align="center">
              <span
                *ngIf="al.ald_file_foto_status == 0"
                class="badge badge-secondary"
                >Sin Archivo</span
              >
              <span
                *ngIf="al.ald_file_foto_status == 1"
                class="badge badge-info"
                >Subido</span
              >
              <span
                *ngIf="al.ald_file_foto_status == 2"
                class="badge badge-warning"
                >A Revisión</span
              >
              <span
                *ngIf="al.ald_file_foto_status == 3"
                class="badge badge-success"
                >Aceptado</span
              >
              <br />
              <div
                class="btn-group"
                role="group"
                aria-label="Basic example"
                *ngIf="al.aldFileFoto != ''"
              >
                <button
                  type="button"
                  class="btn btn-info"
                  (click)="verArchivo(al.aldFileFoto)"
                >
                  <i class="far fa-eye"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  (click)="validaArchivo(al.aluMatricula, 'foto', 3)"
                >
                  <i class="fas fa-check-circle"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="validaArchivo(al.aluMatricula, 'foto', 2)"
                >
                  <i class="far fa-times-circle"></i>
                </button>
              </div>
            </td> -->
            <td align="center">
              <span *ngIf="al.ald_file_credencial_status == 0" class="badge bg-secondary">Sin Archivo</span>
              <span *ngIf="al.ald_file_credencial_status == 1" class="badge bg-info">Subido</span>
              <span *ngIf="al.ald_file_credencial_status == 2" class="badge bg-warning">A Revisión</span>
              <span *ngIf="al.ald_file_credencial_status == 3" class="badge bg-success">Aceptado</span>
              <br />
              <div class="btn-group" role="group" aria-label="Basic example" *ngIf="al.ald_file_credencial != ''">
                <button *ngIf="al.ald_file_credencial_status != 0" type="button" class="btn btn-info" (click)="verArchivo(al.ald_file_credencial)"><i class="fa fa-eye"></i></button>
                <button *ngIf="al.ald_file_credencial_status != 0" type="button" class="btn btn-success" (click)="validaArchivo(al.aluMatricula, 'credencial', 3)"><i class="fa fa-check-circle"></i></button>
                <button *ngIf="al.ald_file_credencial_status != 0" type="button" class="btn btn-danger" (click)="validaArchivo(al.aluMatricula, 'credencial', 2)"><i class="fa fa-times-circle"></i></button>
              </div>
            </td>
            <!-- <td align="center">
              <span
                *ngIf="al.ald_file_recibo_status == 0"
                class="badge badge-secondary"
                >Sin Archivo</span
              >
              <span
                *ngIf="al.ald_file_recibo_status == 1"
                class="badge badge-info"
                >Subido</span
              >
              <span
                *ngIf="al.ald_file_recibo_status == 2"
                class="badge badge-warning"
                >A Revisión</span
              >
              <span
                *ngIf="al.ald_file_recibo_status == 3"
                class="badge badge-success"
                >Aceptado</span
              >
              <br />
              <div
                class="btn-group"
                role="group"
                aria-label="Basic example"
                *ngIf="al.aldFileRecibo != ''"
              >
                <button
                  type="button"
                  class="btn btn-info"
                  (click)="verArchivo(al.aldFileRecibo)"
                >
                  <i class="far fa-eye"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  (click)="validaArchivo(al.aluMatricula, 'recibo', 3)"
                >
                  <i class="fas fa-check-circle"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="validaArchivo(al.aluMatricula, 'recibo', 2)"
                >
                  <i class="far fa-times-circle"></i>
                </button>
              </div>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
