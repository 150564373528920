import { Component, OnInit } from '@angular/core';
import { FechasService } from './fechas.service';
import { FechaModificable } from '../registro/interfaces';

@Component({
  selector: 'app-fechas',
  templateUrl: './fechas.component.html',
  styleUrls: ['./fechas.component.css'],
})
export class FechasComponent implements OnInit {
  hoy = new Date();
  year = this.hoy.getFullYear();
  nuevaFecha = '';
  LETRAS = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'Ñ',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  fechas: FechaModificable[] = [];

  constructor(private srvFechas: FechasService) {}

  ngOnInit(): void {
    this.getFechasYear();
  }

  getFechasYear() {
    this.srvFechas.getFechasYear(this.year).subscribe({
      next: (resp: any) => {
        resp.data.forEach((element: any) => {
          const arregloFecha = element.fecha.split('/');
          const dia = arregloFecha[0];
          const mes = arregloFecha[1] - 1;
          const anio = arregloFecha[2];

          element.fecha = new Date(anio, mes, dia);
          if (element.letras == null) {
            element.letras = [];
          } else {
            element.letras = element.letras.split('');
          }
        });
        this.fechas = resp.data;
      },
      error: (error: any) => {},
    });
  }

  cambiaLetra(laFecha: string, laLetra: string) {
    for (const f of this.fechas) {
      if (f.fecha === laFecha) {
        if (f.letras.includes(laLetra)) {
          f.letras = f.letras.filter((l) => l !== laLetra);
          switch (laLetra) {
            case 'A':
              f.letras = f.letras.filter((l) => l !== 'Á');
              break;
            case 'E':
              f.letras = f.letras.filter((l) => l !== 'É');
              break;
            case 'I':
              f.letras = f.letras.filter((l) => l !== 'Í');
              break;
            case 'O':
              f.letras = f.letras.filter((l) => l !== 'Ó');
              break;
            case 'U':
              f.letras = f.letras.filter((l) => l !== 'Ú');
              break;
          }
        } else {
          console.log('f.letras', f.letras);
          f.letras.push(laLetra);
          switch (laLetra) {
            case 'A':
              f.letras.push('Á');
              break;
            case 'E':
              f.letras.push('É');
              break;
            case 'I':
              f.letras.push('Í');
              break;
            case 'O':
              f.letras.push('Ó');
              break;
            case 'U':
              f.letras.push('Ú');
              break;
          }
        }
        f.letras.sort();
        this.updLetrasFecha(laFecha, f.letras.join(''));
        break;
      }
    }
  }

  agregaFecha() {
    if (this.nuevaFecha.length == 10) {
      this.srvFechas.addFecha(this.nuevaFecha).subscribe({
        next: (resp: any) => {
          this.getFechasYear();
          this.nuevaFecha = '';
        },
        error: (error: any) => {},
      });
    }
  }

  updLetrasFecha(laFecha: string, letras: string) {
    let nuevaFecha = this.convertirFecha(laFecha);
    // let dato = fecha.split('/');
    // const dia = dato[0];
    // const mes = dato[1];
    // const anio = dato[2];
    // const nuevaFecha = `${anio}-${mes}-${dia}`;
    this.srvFechas.updLetrasFecha(nuevaFecha, letras).subscribe({
      next: (resp: any) => {
        this.getFechasYear();
      },
      error: (error: any) => {},
    });
  }

  agregarTodas(laFecha: string) {
    for (const f of this.fechas) {
      if (f.fecha === laFecha) {
        f.letras = [...this.LETRAS];
        f.letras.push('Á');
        f.letras.push('É');
        f.letras.push('Í');
        f.letras.push('Ó');
        f.letras.push('Ú');
        f.letras.sort();
        this.updLetrasFecha(laFecha, f.letras.join(''));
        break;
      }
    }
  }

  quitarTodas(laFecha: string) {
    for (const f of this.fechas) {
      if (f.fecha === laFecha) {
        f.letras = [];
        this.updLetrasFecha(laFecha, f.letras.join(''));
        break;
      }
    }
  }

  convertirFecha(inputFormat: string) {
    function agregarCeros(s: number) {
      return s < 10 ? '0' + s : s;
    }
    const fecha = new Date(inputFormat);
    const dia = agregarCeros(fecha.getDate());
    const mes = agregarCeros(fecha.getMonth() + 1);
    const anio = fecha.getFullYear();

    return `${anio}-${mes}-${dia}`;
    // return `${dia}/${mes}/${anio}`;
  }

  deleteDay(laFecha: string) {
    let nuevaFecha = this.convertirFecha(laFecha);
    // console.log(fecha);
    // let dato = fecha.split('/');
    // const dia = dato[0];
    // const mes = dato[1];
    // const anio = dato[2];
    // const nuevaFecha = `${anio}-${mes}-${dia}`;
    console.log(nuevaFecha);
    this.srvFechas.delDay(nuevaFecha).subscribe({
      next: (resp: any) => {
        console.log('ok');
        this.getFechasYear();
      },
      error: (error: any) => {},
    });
  }

  backDate(){

  }
}
