import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';

const urls = {
  ADD_FECHA: `${env.url}/api/v1/fechas`,
  GET_LETRAS_DIA: `${env.url}/api/v1/fechas/getLetrasDia`,
  GET_FECHAS_YEAR: `${env.url}/api/v1/fechas/getFechasYear`,
  UPD_LETRAS_FECHA: `${env.url}/api/v1/fechas`,
  DEL_DATE: `${env.url}/api/v1/fechas`,
};

@Injectable({
  providedIn: 'root',
})
export class FechasService {
  constructor(private http: HttpClient) {}

  addFecha(fecha: string) {
    return this.http.post(`${urls.ADD_FECHA}`, { fecha });
  }

  getLetrasDia(dia: string) {
    return this.http.get(`${urls.GET_LETRAS_DIA}/${dia}`);
  }

  getFechasYear(year: number) {
    return this.http.get(`${urls.GET_FECHAS_YEAR}/${year}`);
  }

  updLetrasFecha(fecha: string, letras: string) {
    return this.http.put(`${urls.UPD_LETRAS_FECHA}`, { fecha, letras });
  }

  delDay(fecha: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: {fecha},
    };
    return this.http.delete(`${urls.DEL_DATE}`, httpOptions);
  }
}
