<div class="container">
  <div class="card">
    <div class="card-body">
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <div class="row align-items-start">
            <div class="col-md-8">
              <h1 class="display-4" id="titulo">Registro de Captura de Pago</h1>
            </div>
            <div class="col-md-4 align-items-start">
              <img
                src="../assets/logo_cobatab_lema-01.png"
                alt="COBATAB"
                class="img-fluid"
              />
            </div>
          </div>
          <p class="lead" id="subtitulo">Detalles del proceso de registro</p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">Datos de Registro</div>
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="apaterno" class="col-sm-4 col-form-label"
                    >Apellido Paterno:</label
                  >
                  <div class="col-sm-8">
                    <input type="text" class="form-control" />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <label for="amaterno" class="col-sm-4 col-form-label"
                    >Apellido Materno:</label
                  >
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="amaterno" />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <label for="nombre" class="col-sm-4 col-form-label"
                    >Nombre:</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      id="nombre"
                      name="nombre"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <label for="curp" class="col-sm-4 col-form-label"
                    >CURP:</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      id="curp"
                      name="curp"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <label for="lineaCaptura" class="col-sm-4 col-form-label"
                    >Linea de Captura:</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="number"
                      min="0"
                      class="form-control"
                      id="lineaCaptura"
                      name="lineaCaptura"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <label for="recibo" class="col-sm-4 col-form-label"
                    >Recibo:</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="file"
                      class="form-control"
                      id="recibo"
                      name="recibo"
                      accept="application/pdf,image/x-png,image/gif,image/jpeg"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <label for="plantel" class="col-sm-4 col-form-label"
                    >Plantel:</label
                  >
                  <div class="col-sm-8">
                    <select name="plantel" id="plantel" class="form-control">
                      <option
                        *ngFor="let pl of planteles"
                        value="{{ pl.uniadmClave }}"
                      >
                        {{ pl.uniadmDesc }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <label for="correo" class="col-sm-4 col-form-label"
                    >Correo:</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      id="correo"
                      name="correo"
                    />
                  </div>
                </div>
              </div>
            </div>

            <button class="btn btn-outline-primary btn-block">Registrar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
