import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

interface Respuesta {
  error: boolean;
  msg: string;
  data?: any[]
}

@Injectable({
  providedIn: 'root'
})
export class RegistroService {

  private url = `${environment.url}/api`;
  private urlFile = `${environment.url}/api`;

  constructor(
    private http: HttpClient
  ) { }

  getPlanteles() {
    return this.http.get<Respuesta>(`${this.url}/dashboard/getPlanteles`);
  }
}
