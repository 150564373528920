<div class="card">
  <div class="card-header">Secciones</div>
  <div class="card-body">
    <ul class="list-group">
      <li class="list-group-item _cursor" routerLink='/inscripcion'>Registro en Linea</li>
      <li class="list-group-item _cursor" routerLink='/registroDePago'>Registro de Pago</li>
      <li class="list-group-item _cursor" routerLink='/planteles'>Seccion Planteles</li>
      <li class="list-group-item _cursor" routerLink='/controlEscolar'>Seccion Control Escolar</li>
    </ul>
  </div>
</div>
